import React, { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { useWorkspaceId } from '@/hooks/router';

import { IBillingPlansDescription } from '../../utils';

import { FinalCancelStepModal, InitialCancelStepModal } from './ui';

export enum ECancelSubscribeStep {
  Initial = 'initial',
  ConfirmCancel = 'confirmCancel',
  FinalCancel = 'finalCancel',
}

export interface ICancelModalStepProps {
  selectedBillingPlan?: IBillingPlansDescription;
  open: boolean;
  setCurrentStep: React.Dispatch<
    React.SetStateAction<ECancelSubscribeStep | undefined>
  >;
}

interface ICancelModalProps {
  selectedBillingPlan?: IBillingPlansDescription;
  currentStep: ECancelSubscribeStep | undefined;
  setCurrentStep: React.Dispatch<
    React.SetStateAction<ECancelSubscribeStep | undefined>
  >;
}

export const CancelSubscribeModal = ({
  selectedBillingPlan,
  currentStep,
  setCurrentStep,
}: ICancelModalProps) => {
  const workspaceId = useWorkspaceId();
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();
  useEffect(() => {
    amplitude?.logEvent(
      `billing/cancel subscription modal step ${currentStep}`,
      {
        'workspace id': workspaceId,
      }
    );
    posthog?.capture(
      `hire_admin_app:billing/cancel subscription modal step ${currentStep}`,
      {
        'workspace id': workspaceId,
      }
    );
  }, [amplitude, currentStep, workspaceId, posthog]);

  return currentStep ? (
    <>
      <InitialCancelStepModal
        selectedBillingPlan={selectedBillingPlan}
        open={currentStep === ECancelSubscribeStep.Initial}
        setCurrentStep={setCurrentStep}
      />

      <FinalCancelStepModal
        open={currentStep === ECancelSubscribeStep.FinalCancel}
        setCurrentStep={setCurrentStep}
      />
    </>
  ) : null;
};
