import styled from '@emotion/styled';

export const QuestionSettings = styled.div`
  display: grid;
  grid-template-columns:
    minmax(180px, 348px)
    minmax(120px, 348px)
    minmax(110px, 132px)
    minmax(max-content, auto)
    minmax(42px, 68px);
  gap: 8px;
  background-color: ${(props) => props.theme.colors.purple[5]};
  padding: 16px 24px;

  > :last-child {
    grid-column: -2 / span 1;
    justify-self: end;
  }
`;
