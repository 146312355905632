import React from 'react';

export const CheersIllustration = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="58"
    height="68"
    viewBox="0 0 58 68"
    fill="none"
  >
    <path
      d="M1.12434 67.5658C0.51719 67.8759 -0.164374 67.304 0.0355479 66.6522L11.4747 29.3589L35.8647 49.8245L1.12434 67.5658Z"
      fill="#FFA195"
    />
    <ellipse
      cx="24.786"
      cy="38.268"
      rx="16.2952"
      ry="7.98356"
      transform="rotate(40 24.786 38.268)"
      fill="#EF8274"
    />
    <ellipse
      cx="24.9498"
      cy="20.0061"
      rx="4.0561"
      ry="4.06787"
      fill="#FFA195"
    />
    <ellipse
      cx="54.4209"
      cy="28.0417"
      rx="2.75028"
      ry="2.75827"
      fill="#ABA0AF"
    />
    <rect
      width="6.68003"
      height="11.837"
      rx="1.05897"
      transform="matrix(-0.862468 -0.506111 0.508585 -0.861012 38.4573 29.9153)"
      fill="#FFDE91"
    />
    <path
      d="M37.9132 37.2306C37.6743 37.0546 37.7149 36.6862 37.9863 36.5667L47.4687 32.3918C47.7407 32.272 48.0404 32.492 48.0079 32.7874L46.8718 43.1108C46.8393 43.4062 46.499 43.5553 46.2596 43.379L37.9132 37.2306Z"
      fill="#FF897A"
    />
    <path
      d="M19.9048 4.60382C19.6659 4.42785 19.7064 4.05945 19.9778 3.93996L25.4511 1.53015C25.7231 1.41041 26.0228 1.63034 25.9903 1.9258L25.3345 7.88448C25.302 8.17995 24.9617 8.32904 24.7223 8.15269L19.9048 4.60382Z"
      fill="#FFE19C"
    />
    <rect
      x="20.5172"
      y="37.1553"
      width="8.11288"
      height="5.11509"
      rx="0.385866"
      transform="rotate(-23.5519 20.5172 37.1553)"
      fill="#FFDCD7"
    />
    <rect
      x="48.6929"
      y="16.0338"
      width="8.37857"
      height="4.04674"
      rx="0.385866"
      transform="rotate(-3.35002 48.6929 16.0338)"
      fill="#FFE4A7"
    />
    <rect
      x="34.814"
      y="4.94312"
      width="7.31257"
      height="5.11509"
      rx="0.385866"
      transform="rotate(65.1649 34.814 4.94312)"
      fill="#FFA195"
    />
  </svg>
);
