export const JOB_ROLES = [
  {
    id: 'Recruiter',
    label: 'Recruiter',
  },
  {
    id: 'Operations Manager',
    label: 'Operations Manager',
  },
  {
    id: 'Hiring Manager',
    label: 'Hiring Manager',
  },
  {
    id: 'Founder',
    label: 'Founder',
  },
  {
    id: 'Leadership',
    label: 'Leadership',
  },
  {
    id: 'I’m looking for a new job',
    label: 'I’m looking for a new job',
  },
  {
    id: 'Other',
    label: 'Other',
  },
];
