import styled from '@emotion/styled';

export const TooltipContent = styled.div`
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.typography.colorPrimary};
  background: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.shadow.popup};
  width: max-content;
`;

export const TooltipContentGrid = styled(TooltipContent)`
  padding: 20px;
  display: grid;
  grid-template-areas: 'article aside' 'footer footer';
  gap: 16px;
  max-width: 274px;
  position: relative;
  top: 38px;

  article {
    grid-area: article;
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: left;

    h2 {
      margin: 0;
      color: ${({ theme }) => theme.typography.colorPrimary};
      font-size: 14px;
      font-weight: 600;
    }

    p {
      margin: 0;
      color: ${({ theme }) => theme.typography.colorSecondary};
      font-size: 12px;
      font-weight: 500;
    }
  }

  aside {
    grid-area: aside;
    display: flex;
  }

  footer {
    grid-area: footer;

    button {
      width: 100%;
    }
  }
`;
