import { ICandidate } from '@/features/candidate';
import { IPipelineCandidate } from '@/features/pipeline';

export const getCandidateServiceName = (
  candidate: ICandidate | undefined
): 'github' | 'linkedin' | null => {
  if (!candidate) return null;

  const match = /^https?:\/\/([a-z.]+)/.exec(candidate.url);

  if (match) {
    const [, domain] = match;

    switch (domain) {
      case 'github.com':
        return 'github';
      case 'linkedin.com':
      case 'www.linkedin.com':
        return 'linkedin';
      default:
        return null;
    }
  } else {
    return null;
  }
};

export const getCandidateEmail = (
  candidate: ICandidate | IPipelineCandidate | undefined
): string => {
  if (!candidate) return '';

  return (
    candidate.contactInfo.contactEmail.trim() || candidate.email.trim() || ''
  );
};

/**
 *
 * Constructs string with candidate's display name.
 * Uses first and last name if available, falls back to deprecated fullName and name fields.
 */
export function getCandidateDisplayName(
  candidate: ICandidate | IPipelineCandidate
) {
  const displayName =
    `${candidate.contactInfo.firstName} ${candidate.contactInfo.lastName}`.trim();

  if (!displayName) {
    return candidate.contactInfo.fullName.trim() || candidate.name.trim();
  }

  return displayName;
}

export function getCandidateNameOrEmail(
  candidate: IPipelineCandidate | ICandidate
): string {
  return getCandidateDisplayName(candidate) || getCandidateEmail(candidate);
}

export function getCandidateFirstName(
  candidate: ICandidate | IPipelineCandidate
) {
  const contactFirstName = candidate.contactInfo.firstName.trim();

  if (!contactFirstName) {
    const [computedFirstName, _computedLastName] =
      splitCandidateName(candidate);
    return computedFirstName;
  }

  return contactFirstName;
}

export function getCandidateLastName(
  candidate: ICandidate | IPipelineCandidate
) {
  const contactLastName = candidate.contactInfo.lastName.trim();

  if (!contactLastName) {
    const [_computedFirstName, computedLastName] =
      splitCandidateName(candidate);
    return computedLastName;
  }

  return contactLastName;
}

function splitCandidateName(
  candidate: ICandidate | IPipelineCandidate
): [string, string] {
  const fullName =
    candidate.contactInfo.fullName.trim() || candidate.name.trim();
  const [firstName, ...lastName] = fullName.split(/\s+/);
  return [firstName || '', lastName.join(' ') || ''];
}

/** Removes brackets from demo candidate lastname, so the initials can be "XD" instead of "X[" */
const removeBrackets = (str: string) => str.replace(/[\[\]]/g, '');

export const getCandidateInitials = (
  candidate: ICandidate | undefined
): string => {
  if (!candidate) return '';

  const firstName = getCandidateFirstName(candidate);
  const lastName = candidate.isDemo
    ? removeBrackets(getCandidateLastName(candidate))
    : getCandidateLastName(candidate);
  const email = getCandidateEmail(candidate);

  return (
    `${firstName.charAt(0)}${lastName.charAt(0)}` ||
    (email && `${email.charAt(0)}`) ||
    ''
  );
};

export const getCandidateLinkedinUrl = (linkedinUrl: string): string | null => {
  const base = 'https://linkedin.com';

  try {
    if (/^https?:\/\/([a-z]{2,3}\.)?linkedin\.com/.test(linkedinUrl)) {
      const url = new URL(linkedinUrl);
      return base + url.pathname;
    }

    if (/^([a-z]{2,3}\.)?linkedin\.com/.test(linkedinUrl)) {
      const url = new URL('https://' + linkedinUrl);
      return base + url.pathname;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getCandidateGithubUrl = (githubUrl: string): string | null => {
  const base = 'https://github.com';

  try {
    if (/^https:\/\/([a-z]{2,3}\.)?github\.com/.test(githubUrl)) {
      const url = new URL(githubUrl);
      return base + url.pathname;
    }

    if (/^([a-z]{2,3}\.)?github\.com/.test(githubUrl)) {
      const url = new URL('https://' + githubUrl);
      return base + url.pathname;
    }

    return null;
  } catch (error) {
    return null;
  }
};

/**
 * Checks if candidate is a recommended one that haven't done the test yet so they are still anonymized and some actions should be disabled.
 */
export function isCandidateAnonymizedRecommendation(
  candidate: ICandidate | IPipelineCandidate
) {
  return candidate.isRecommendation && candidate.hireState === 'invited';
}
