import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSContentBlock } from '@hundred5/design-system';

import { useGenericPricingUpsellModalContext } from '@/features/upsell';
import { usePlanLimits } from '@/hooks/planLimits';
import { useHistory, useWorkspaceId } from '@/hooks/router';

export function ProfileSettingsSSO() {
  const workspaceId = useWorkspaceId();
  const history = useHistory();
  const { openPricingUpsell } = useGenericPricingUpsellModalContext();
  const canAccessFeature = usePlanLimits();
  const canAccessSSO = canAccessFeature('sso');

  return (
    <DSContentBlock>
      <DSContentBlock.Title>Single sign-on (SSO)</DSContentBlock.Title>
      <DSContentBlock.Content>
        <Container>
          <Description>
            Set up single sign-on with identity providers that support the SAML
            protocol.{' '}
            <Link
              href="https://support.hire.toggl.com/en/articles/10964341-how-to-set-up-single-sign-on-sso"
              target="_blank"
            >
              See detailed instructions.
            </Link>
          </Description>
          <DSButton
            variant="secondary"
            onClick={() => {
              if (canAccessSSO) {
                history.push(
                  workspaceId
                    ? `/admin/${workspaceId}/account/sso`
                    : '/admin/account/sso'
                );
              } else {
                openPricingUpsell('generic', 'premium');
              }
            }}
          >
            Manage SSO profiles
          </DSButton>
        </Container>
      </DSContentBlock.Content>
    </DSContentBlock>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;

const Link = styled.a`
  color: ${(props) => props.theme.colors.purple[60]};
  text-decoration: underline;
`;

const Description = styled.div`
  color: ${(props) => props.theme.colors.purple[60]};
`;
