import React from 'react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from 'react-router-dom';
import { useMedia } from 'react-use';

import { Icon } from '@/features/common';
import { useWorkspacePermission } from '@/features/permissions';
import { useWorkspace } from '@/features/workspace';
import { planLevelsType, premiumPlans } from '@/hooks/planLimits';
import { useHistory } from '@/hooks/router';

export function Settings() {
  const history = useHistory();
  const isActive =
    history.location.pathname.includes('/settings/') ||
    history.location.pathname.includes('profile');
  const isMobile = useMedia('(max-width: 768px)');

  const workspace = useWorkspace();
  const isPremiumPlan =
    workspace &&
    premiumPlans.includes(planLevelsType[workspace.subscriptionPlan]);

  const canOpenUserSettings = useWorkspacePermission()('user settings', 'read');
  const canOpenWorkspaceSettings = useWorkspacePermission()(
    'workspace settings',
    'read'
  );

  const linkTo = !workspace
    ? '/admin/profile'
    : isPremiumPlan
      ? canOpenWorkspaceSettings
        ? `/admin/${workspace.id}/settings/team`
        : `/admin/${workspace.id}/settings/profile`
      : `/admin/${workspace.id}/settings/billing`;

  return !workspace ||
    ((canOpenUserSettings || canOpenWorkspaceSettings) && !isMobile) ? (
    <SettingsContainer to={linkTo} active={isActive}>
      <Icon
        icon={isActive ? solid('gear') : regular('gear')}
        color="white"
        fontSize="14px"
      />
      <span>Settings</span>
    </SettingsContainer>
  ) : null;
}

const SettingsContainer = styled(Link, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'active',
})<{ active: boolean }>`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 56px;
  width: 100%;
  padding: 0 16px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.orange[120] : theme.colors.purple[100]};

  &:hover {
    background-color: ${({ active, theme }) =>
      active ? theme.colors.orange[120] : theme.colors.purple[95]};
  }
`;
