import styled from '@emotion/styled';

export const Container = styled.div`
  display: grid;
  grid-template-areas:
    'icon body action'
    'email email .'
    'details   details  .';
  grid-template-columns: auto 1fr auto;
  column-gap: 12px;
  row-gap: 16px;
`;
