import { TQuestionType } from '@/features/questions';

export const LIBRARY_QUESTION_TYPE_OPTIONS: {
  id: TQuestionType | string;
  label: string;
  labelText: string;
  group: string;
  disabled?: boolean;
}[] = [
  {
    id: 'placeholder-close-ended',
    label: 'Choice or numeric input',
    labelText: 'Choice or numeric input',
    group: 'Auto evaluation (recommended)',
  },
  {
    id: 'free-text',
    label: 'Free text',
    labelText: 'Free text',
    group: 'Requires manual evaluation',
  },
  {
    id: 'code-input',
    label: 'Coding',
    labelText: 'Coding',
    group: 'Requires manual evaluation',
  },
];
