import React from 'react';
import styled from '@emotion/styled';

interface ProgressBarProps {
  value: number;
}

export function ProgressBar({ value }: ProgressBarProps) {
  return (
    <ProgressContainer>
      <ProgressValue>{value.toFixed()}%</ProgressValue>
      <Bar>
        <BarFill width={`${value}%`} />
      </Bar>
    </ProgressContainer>
  );
}

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

const ProgressValue = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
`;

const Bar = styled.div`
  width: 132px;
  height: 5px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
`;

const BarFill = styled.div<{ width: string }>`
  height: 5px;
  background: ${({ theme }) => theme.colors.orange[100]};
  border-radius: 3px;
  width: ${(props) => props.width};
`;
