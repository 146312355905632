import { usePostHog } from 'posthog-js/react';

import { ClientError } from '@/errors';
import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { TId } from '@/features/common';
import { IDashboardJobOpening } from '@/features/dashboard';
import { IJobOpening } from '@/features/job-opening';
import { getJobOpeningUrlForCandidate } from '@/features/job-opening/utils/job-opening-url';
import { trackSmartlookEvent } from '@/smartlook';

import { getTryTestToken } from '../api';
import { ITest } from '../types';

export function useRedirectToTryTheTestYourself() {
  const posthog = usePostHog();
  const amplitude = getAmplitudeClient();

  function trackTestTry(jobOpening: IJobOpening | IDashboardJobOpening) {
    trackSmartlookEvent('tried_the_test_themselves');
    amplitude?.logEvent('tried the test themselves', {
      'job opening id': jobOpening.id,
      'job opening name': jobOpening.name,
    });

    posthog?.capture('hire_admin_app:tried the test themselves', {
      'job opening id': jobOpening.id,
      'job opening name': jobOpening.name,
    });
  }

  async function redirectToTryTheTestYourself(
    jobOpening: IJobOpening,
    workspaceId: TId,
    test: ITest
  ): Promise<void>;
  async function redirectToTryTheTestYourself(
    jobOpening: IDashboardJobOpening,
    workspaceId: TId,
    test?: ITest
  ): Promise<void>;
  async function redirectToTryTheTestYourself(
    jobOpening: IJobOpening | IDashboardJobOpening,
    workspaceId: TId,
    test?: ITest
  ) {
    const isMobile = window.matchMedia('(max-width: 768px)').matches;
    const backHref = `${window.location.origin}/admin/${workspaceId}/openings${
      isMobile ? '?desktopOnly=true' : `/${jobOpening.id}`
    }`;
    let url: string | null = null;

    if (!test && !('testId' in jobOpening)) {
      throw new Error(
        'redirectToTryTheTestYourself requires IDashboardJobOpening OR (IJobOpening AND ITest) as input'
      );
    }

    try {
      const { token } = await getTryTestToken({
        // @ts-ignore `test` should always be defined here as function would throw if it isn't and TS should protect against that anyway, but type narrowing doesn't get that
        testId: 'testId' in jobOpening ? jobOpening.testId : test.id,
      });

      trackTestTry(jobOpening);

      url = getJobOpeningUrlForCandidate({
        jobOpeningSlug: jobOpening.slug,
        hashedTestId: test?.hashedTestId,
        queryParams: {
          candidateToken: token,
          skipConfirmStart: true,
          backHref,
        },
      });
    } catch (error) {
      if (error instanceof ClientError && error.type === 'ReachedNrOfTries') {
        url = getJobOpeningUrlForCandidate({
          jobOpeningSlug: jobOpening.slug,
          hashedTestId: test?.hashedTestId,
          queryParams: {
            skipConfirmStart: true,
            backHref,
          },
        });
      } else {
        throw error;
      }
    } finally {
      if (!url) {
        return;
      }

      window.location.href = url;
    }
  }

  return redirectToTryTheTestYourself;
}
