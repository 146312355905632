import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

import { Badge } from './badge';

export const Link = styled(RouterLink, {
  shouldForwardProp: (prop) =>
    isPropValid(prop) &&
    !['disabled', 'active', 'persistentBadge'].includes(prop),
})<{ disabled: boolean; active: boolean; persistentBadge: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  gap: 12px;
  height: 38px;
  width: 100%;
  padding: 6px 16px;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.orange[120] : theme.colors.purple[100]};

  &:hover {
    background-color: ${({ active, theme }) =>
      active ? theme.colors.orange[120] : theme.colors.purple[95]};

    ${Badge} {
      display: flex;

      ${({ active, persistentBadge, theme }) =>
        !active &&
        persistentBadge &&
        css`
          background-color: ${theme.colors.purple[100]};
        `}
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;
