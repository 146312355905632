import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSSearchInput } from '@hundred5/design-system';
import { usePostHog } from 'posthog-js/react';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { useWorkspacePermission } from '@/features/permissions';
import { useGenericPricingUpsellModalContext } from '@/features/upsell';
import { usePlanLimits } from '@/hooks/planLimits';

interface SearchBarProps {
  placeholder: string;
  value: string;
  reset: () => void;
  handleSearchChange: (event: any) => void;
  handleSearchBlur?: () => void;
  handleCreateCustomTest: (event: any) => void;
}

export function SearchBar({
  placeholder,
  value,
  reset,
  handleSearchChange,
  handleCreateCustomTest,
  handleSearchBlur,
}: SearchBarProps) {
  const canCreateJobOpening = useWorkspacePermission()(
    'job openings',
    'create'
  );
  const canAccessFeature = usePlanLimits();
  const canCreateCustomTest = canAccessFeature('create_custom_test');
  const { openPricingUpsell } = useGenericPricingUpsellModalContext();
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();

  return (
    <Top>
      <SearchInputContainer>
        <DSSearchInput
          placeholder={placeholder}
          value={value}
          showResetButton
          reset={reset}
          onChange={handleSearchChange}
          onBlur={handleSearchBlur}
        />
      </SearchInputContainer>
      <CreateCustomTestButton
        variant={'tertiary'}
        onClick={(event) => {
          if (canCreateCustomTest) {
            handleCreateCustomTest && handleCreateCustomTest(event);
            amplitude?.logEvent('job templates/click top new test');
            posthog?.capture('hire_admin_app:job templates/click top new test');
          } else {
            openPricingUpsell('unlimitedSkillsTests');
          }
        }}
        disabled={!canCreateJobOpening}
        data-rh={
          !canCreateJobOpening
            ? 'Access restricted. Contact workspace admin to change your user rights.'
            : null
        }
      >
        Create a custom test
      </CreateCustomTestButton>
    </Top>
  );
}

const Top = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  padding-top: 24px;
  margin-bottom: 34px;

  @media all and (max-width: 768px) {
    margin-bottom: 24px;
    width: 100%;
  }
`;

const SearchInputContainer = styled.div`
  width: 670px;
`;

const CreateCustomTestButton = styled(DSButton)`
  @media all and (max-width: 768px) {
    display: none;
  }
`;
