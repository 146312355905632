import React, { createContext, useContext, useState } from 'react';

type TWizardContext<T> = {
  data: T;
  stepCount: number;
  currentStep: number;
  submitting: boolean;
  setData: (data: T) => void;
  goBack: () => void;
  goForward: () => void;
  onSubmit: (data: T) => Promise<boolean>;
  onComplete?: () => void;
};

const WizardContext = createContext<TWizardContext<any>>({
  data: {},
  stepCount: 0,
  currentStep: 0,
  submitting: false,
  setData: () => {},
  goBack: () => {},
  goForward: () => {},
  onSubmit: () => Promise.resolve(true),
  onComplete: () => {},
});

export function useWizard<T>() {
  const context = useContext<TWizardContext<T>>(WizardContext);

  if (!context) {
    throw new Error('useWizard must be used within a WizardProvider');
  }
  return context;
}

export function WizardProvider<T>({
  stepCount,
  initalData,
  onSubmit,
  onComplete,
  children,
}: {
  stepCount: number;
  initalData?: T;
  onSubmit: (data: T) => Promise<any>;
  onComplete?: () => void;
  children: React.ReactNode;
}) {
  const [data, setData] = useState<T | undefined>(initalData);
  const [currentStep, setCurrentStep] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  const goBack = () => {
    if (currentStep === 0) {
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const goForward = () => {
    if (currentStep === stepCount) {
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const handleSubmit = async (data: T): Promise<any> => {
    try {
      setSubmitting(true);
      await onSubmit(data);
      return true;
    } catch (error) {
      return false;
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <WizardContext.Provider
      value={{
        data,
        stepCount,
        currentStep,
        submitting,
        setData,
        goBack,
        goForward,
        onSubmit: handleSubmit,
        onComplete,
      }}
    >
      {children}
    </WizardContext.Provider>
  );
}

function WizardProviderStep({
  index,
  children,
}: {
  index: number;
  children: React.ReactNode;
}) {
  const { currentStep } = useWizard();

  if (currentStep === index) {
    return <>{children}</>;
  }

  return null;
}

WizardProvider.Step = WizardProviderStep;
