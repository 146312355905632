import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';
import { useMeasure } from 'react-use';

import { Icon, Label } from '@/features/common';
import { useClickOutside } from '@/features/common/hooks/use-click-outside';

import { FormikInputMultipleEmailsField } from '../formik-input-multiple-emails';

export const EmailList = ({
  emails,
  addCCToEmails,
  cc,
  bcc,
}: {
  emails: string[];
  addCCToEmails?: boolean;
  cc?: string[];
  bcc?: string[];
}) => {
  const [emailsListToShow, setEmailsListToShow] = useState<string[]>(emails);
  const [applyShowMoreEmails, setApplyShowMoreEmails] = useState(false);
  const [showCCField, setShowCCField] = useState(false);
  const [showBCCField, setShowBCCField] = useState(false);
  const [containerRef, { width: containerWidth }] =
    useMeasure<HTMLDivElement>();
  const clickOutsideRef = useRef<HTMLDivElement>(null);
  const [contentRef, { width: contentWidth }] = useMeasure<HTMLDivElement>();

  useEffect(() => setEmailsListToShow(emails), [emails]);

  const showMoreEmails = useMemo(() => {
    if (
      containerWidth + 24 + (emails.length - 1) * 4 < contentWidth ||
      emails.length !== emailsListToShow.length
    ) {
      if (emails.slice(0, 2).toString().length > 60) {
        setEmailsListToShow(emails.slice(0, 1));
      } else {
        setEmailsListToShow(emails.slice(0, 2));
      }

      return true;
    } else {
      return false;
    }
  }, [containerWidth, contentWidth, emails, emailsListToShow.length]);

  useClickOutside(clickOutsideRef, () => {
    setApplyShowMoreEmails(false);
  });

  return (
    <div data-recording-ignore="mask">
      <TitleContainer>
        <Label>{emails.length > 1 ? 'Candidates selected' : 'TO'}</Label>{' '}
        {emails.length > 1 && (
          <CandidateCounter>
            {`${emails.length}/50`}
            <Icon
              icon={regular('circle-info')}
              fontSize="10px"
              color="purple-60"
              data-rh="You can email only 50 candidates at once"
              data-rh-at="right"
            />{' '}
          </CandidateCounter>
        )}
      </TitleContainer>
      <div ref={clickOutsideRef}>
        <EmailListWrapper
          ref={containerRef}
          showMoreEmails={applyShowMoreEmails}
        >
          <EmailWrapper
            ref={contentRef}
            showMoreEmails={applyShowMoreEmails}
            singleEmail={emails.length === 1}
          >
            {emailsListToShow.map((email, index) => (
              <EmailBadge color="white" key={`${email}-${index}`}>
                {email}
              </EmailBadge>
            ))}
            {showMoreEmails && (
              <ShowMoreEmails
                variant="tertiary"
                size="small"
                onClick={() => {
                  setEmailsListToShow(emails);
                  setApplyShowMoreEmails(true);
                }}
              >
                {emails.length - emailsListToShow.length} more
              </ShowMoreEmails>
            )}
            {addCCToEmails && (
              <EmailButtonsWrapper>
                {!showCCField && (
                  <EmailButtons onClick={() => setShowCCField(!showCCField)}>
                    CC
                  </EmailButtons>
                )}
                {!showBCCField && (
                  <EmailButtons onClick={() => setShowBCCField(!showBCCField)}>
                    BCC
                  </EmailButtons>
                )}
              </EmailButtonsWrapper>
            )}
          </EmailWrapper>
        </EmailListWrapper>
      </div>
      {showCCField && (
        <CCWrapper>
          <FormikInputMultipleEmailsField
            placeholder="Add one or more emails"
            name="cc"
            label="CC"
            disableFileInput={true}
          />
        </CCWrapper>
      )}
      {showBCCField && (
        <CCWrapper>
          <FormikInputMultipleEmailsField
            placeholder="Add one or more emails"
            name="bcc"
            label="BCC"
            disableFileInput={true}
          />
        </CCWrapper>
      )}
    </div>
  );
};

//region Styles
const EmailListWrapper = styled.div<{ showMoreEmails: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  max-height: 208px;
  overflow-y: auto;

  ${({ showMoreEmails }) =>
    showMoreEmails &&
    `
    max-height: fit-content;
  `}
`;

const EmailWrapper = styled.div<{
  showMoreEmails: boolean;
  singleEmail: boolean;
}>`
  border: ${({ singleEmail, theme }) =>
    singleEmail && `1px solid ${theme.colors.purple[40]}`};
  padding: 5px ${({ singleEmail }) => (singleEmail ? '12px' : '0')};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  min-width: fit-content;

  gap: 4px;
  width: 100%;
  ${({ showMoreEmails }) =>
    showMoreEmails &&
    `
     flex-wrap: wrap;
    `}
`;

const EmailBadge = styled.div`
  display: inline-block;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.purple[40]};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.purple[100]};
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 500;
`;

const EmailButtons = styled.button`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.purple[60]};
  border: 0;
  background: transparent;
  cursor: pointer;
`;

const EmailButtonsWrapper = styled.div`
  margin-left: auto;
`;

const CCWrapper = styled.div`
  margin-top: 8px;
`;

const ShowMoreEmails = styled(DSButton)`
  background: ${({ theme }) => theme.colors.purple[5]};
  height: 24px;
`;

const CandidateCounter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  background: ${({ theme }) => theme.colors.warning[20]};
  height: 15px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  color: ${({ theme }) => theme.colors.purple[90]};
  padding-left: 8px;
  padding-right: 8px;
  gap: 4px;
`;

const TitleContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  gap: 8px;
`;

//endregion
