import * as React from 'react';
import styled from '@emotion/styled';
import ReactHintFactory from 'react-hint';
import 'react-hint/css/index.css';
import { useMedia } from 'react-use';

const ReactHint = ReactHintFactory(React);

interface TooltipProps {
  delay?: { show?: number; hide?: number } | number;
  attribute?: string;
  persist?: boolean;
  onRenderContent?: (target: any, content: string) => React.ReactNode;
}

export function Tooltip({
  delay = 100,
  attribute = 'data-rh',
  persist,
  onRenderContent,
}: TooltipProps) {
  const isMobile = useMedia('(max-width: 768px)');

  if (isMobile) return null;

  return (
    <TooltipContainer>
      <ReactHint
        events
        autoPosition
        delay={delay}
        attribute={attribute}
        persist={persist}
        onRenderContent={
          onRenderContent
            ? onRenderContent
            : (target, content) => {
                return (
                  <Content width={getTooltipWidth(attribute, target.dataset)}>
                    {target.dataset.title ? (
                      <Title>{target.dataset.title}</Title>
                    ) : null}
                    {content}
                  </Content>
                );
              }
        }
      />
    </TooltipContainer>
  );
}

const getTooltipWidth = (attribute: string, targetDataset: DOMStringMap) => {
  const baseKey = attribute
    .replace(/^data-/, '')
    .replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());

  const widthKey = `${baseKey}Width`;

  return targetDataset[widthKey] ?? '325px';
};

const TooltipContainer = styled.div`
  .react-hint {
    z-index: 100000;
  }

  .react-hint--top:after {
    border-top-color: ${(props) => props.theme.colors.white};
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.1));
  }

  .react-hint--left:after {
    border-left-color: ${(props) => props.theme.colors.white};
    filter: drop-shadow(2px 0 1px rgba(0, 0, 0, 0.1));
  }

  .react-hint--right:after {
    border-right-color: ${(props) => props.theme.colors.white};
    filter: drop-shadow(-2px 0 1px rgba(0, 0, 0, 0.1));
  }

  .react-hint--bottom:after {
    border-bottom-color: ${(props) => props.theme.colors.white};
    filter: drop-shadow(0px -2px 1px rgba(0, 0, 0, 0.1));
  }

  .react-hint--top {
    margin-top: -4px;
  }

  .react-hint--left {
    margin-left: -4px;
  }

  .react-hint--right {
    margin-left: 4px;
  }

  .react-hint--bottom {
    margin-top: 4px;
  }
`;

const Content = styled.div<{ width: string }>`
  padding: 8px 16px;
  font-size: 12px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.typography.colorPrimary};
  box-shadow: ${(props) => props.theme.shadow.popup};
  width: max-content;
  max-width: ${(props) => props.width};
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`;
