import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';

import { useFreeTrial } from '@/features/billing';
import { Icon } from '@/features/common';
import {
  useStartWorkspaceFreeTrialMutation,
  useWorkspace,
} from '@/features/workspace';
import { useHistory } from '@/hooks/router';

import analyticsImage from './assets/analytics.svg';
import asyncInterviewImage from './assets/async-interview.png';
import homeworkImage from './assets/homework.svg';

interface TestUpsellProps {
  variant: 'homework' | 'async-interview' | 'analytics';
  title: string;
  text: string;
}

export const TestUpsell = ({ variant, title, text }: TestUpsellProps) => {
  const history = useHistory();
  const { isTrialAvailable } = useFreeTrial();
  const workspace = useWorkspace();
  const startFreeTrial = useStartWorkspaceFreeTrialMutation();

  const handleOnClick = async () => {
    if (isTrialAvailable && variant !== 'homework') {
      await startFreeTrial.mutate({
        workspaceId: workspace?.id!,
        subscribePlan: workspace?.subscriptionPlan!,
      });
    } else {
      history.push(`/admin/${workspace?.id}/settings/billing`);
    }
  };

  return (
    <Container>
      <Wrapper>
        {variant === 'homework' && <FullImage src={homeworkImage} />}
        {variant === 'analytics' && <img src={analyticsImage} />}
        {variant === 'async-interview' && (
          <AsyncWrapper>
            <img src={asyncInterviewImage} width={350} />
            <List>
              <Item>Communication</Item>
              <Item>Technical Support</Item>
              <Item>Personality</Item>
            </List>
          </AsyncWrapper>
        )}
      </Wrapper>
      <Title>{title}</Title>
      <Text>{text}</Text>
      {variant === 'async-interview' || variant === 'analytics' ? (
        <DSButton
          variant={variant === 'analytics' ? 'secondary' : 'primary-purple'}
          onClick={handleOnClick}
        >
          {isTrialAvailable ? 'Start 14 day trial' : 'Upgrade'}
        </DSButton>
      ) : (
        <ButtonWrapper>
          <DSButton
            variant="secondary"
            onClick={() => {
              window
                .open(
                  'https://toggl.com/hire/demo?utm_source=product&utm_medium=button&utm_campaign=homework+upsell',
                  '_blank'
                )
                ?.focus();
            }}
          >
            Book a demo
          </DSButton>
          <DSButton variant="primary-purple" onClick={handleOnClick}>
            Upgrade
          </DSButton>
        </ButtonWrapper>
      )}
      {isTrialAvailable && (
        <SmallText>
          No credit card required
          <Icon
            data-rh={
              'If you have already provided your billing details, you will be charged automatically after your trial ends.'
            }
            data-rh-at="bottom"
            icon={regular('info-circle')}
            height={12}
            width={12}
          />
        </SmallText>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  padding: 52px 16px;
  max-width: 580px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`
  padding: 16px 4px 0;
  background: ${({ theme }) => theme.colors.peach[20]};
  margin-auto;
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;  
  margin-bottom: 16px;
`;

const AsyncWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FullImage = styled.img`
  width: 100%;
`;

const List = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding-bottom: 16px;
  padding-left: 0;
  margin-top: -12px;
`;

const Item = styled.li`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.purple[100]};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const Title = styled.h3`
  font-family: 'GT Haptik';
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 4px;
  text-align: center;
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.purple[60]};
  margin-bottom: 24px;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
`;

const SmallText = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.purple[60]};
  text-align: center;
  margin-top: 8px;
`;
