import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { ClientError } from '@/errors';
import {
  IEmailFailedResponse,
  IEmailResponse,
  IInvitationEmailParams,
  useEmailResultModalContext,
} from '@/features/email';
import { useNotifications } from '@/features/notifications';
import { PIPELINE_CANDIDATES_KEY } from '@/features/pipeline';
import { useApiError } from '@/hooks/api';
import { parseAPIData } from '@/utils/parsers';

import { sendInvitationEmail } from '../api/emails';

interface ISendInvitationEmailMutationVariables {
  invitationEmailParams: IInvitationEmailParams;
}

export const useSendInvitationEmailMutation = (
  options?: UseMutationOptions<any, IEmailFailedResponse>
) => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotifications();
  const handleApiError = useApiError();
  const emailResultModalContext = useEmailResultModalContext();

  return useMutation(
    (variables: ISendInvitationEmailMutationVariables) =>
      sendInvitationEmail(variables.invitationEmailParams),
    {
      meta: {
        skipDefaultErrorHandler: true,
      },
      onSuccess: (result, variables) => {
        queryClient.invalidateQueries([
          ...PIPELINE_CANDIDATES_KEY,
          variables.invitationEmailParams.jobOpeningId,
          variables.invitationEmailParams.testTakerCategoryId,
        ]);
        if (result?.failedRecipientEmailAddress?.length) {
          emailResultModalContext.setResultEmails(result);
        } else {
          addNotification(
            variables.invitationEmailParams.sendAt
              ? {
                  type: 'email_scheduled',
                  meta: { date: variables.invitationEmailParams.sendAt },
                }
              : { type: 'email_sent' }
          );
        }
      },
      onError: (err) => {
        if (
          err instanceof ClientError &&
          err?.response?.data?.failed_recipient_email_address?.length
        ) {
          const parsedError = parseAPIData<IEmailResponse>(err?.response?.data);
          emailResultModalContext.setResultEmails(parsedError);
        } else {
          handleApiError(err);
        }
      },
      ...(options as any),
    }
  );
};
