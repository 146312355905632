import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { DSButton, DSContentBlock } from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { object, string } from 'yup';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { FormikInputField, PromptIfDirty, TId } from '@/features/common';
import {
  useInterviewQuery,
  useUpdateInterviewMutation,
} from '@/features/interview';
import { useNotifications } from '@/features/notifications';
import { useJobOpeningPermission } from '@/features/permissions';
import { useJobOpeningId, useWorkspaceId } from '@/hooks/router';

interface InterviewSettingsProps {
  interviewId: TId;
}

interface Settings {
  name: string;
  schedulingLink: string;
}

const validationSchema = object().shape({
  name: string().required("This can't be blank!"),
  schedulingLink: string()
    .nullable()
    .max(256, "Scheduling link can't be longer than 256 characters!"),
});

export function InterviewSettings({ interviewId }: InterviewSettingsProps) {
  const { addNotification } = useNotifications();
  const workspaceId = useWorkspaceId();
  const jobOpeningId = useJobOpeningId();
  const canUpdate = useJobOpeningPermission()('test', 'update');
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();
  const { data: interview } = useInterviewQuery({ interviewId });
  const updateInterviewMutation = useUpdateInterviewMutation({ interviewId });

  const initialSettings: Settings = {
    name: interview?.name ?? '',
    schedulingLink: interview?.schedulingLink ?? '',
  };

  useEffect(() => {
    amplitude?.logEvent(`page view/open interview settings`, {
      'workspace id': workspaceId,
      'job opening id': jobOpeningId,
      'interview id': interviewId,
    });
    posthog?.capture(`hire_admin_app:page view/open interview settings`, {
      'workspace id': workspaceId,
      'job opening id': jobOpeningId,
      'interview id': interviewId,
    });
  }, [amplitude, interviewId, jobOpeningId, workspaceId, posthog]);

  const handleSubmit = async (values: Settings) => {
    if (!interview) return;

    const schedulingLinkChanged =
      values.schedulingLink !== initialSettings.schedulingLink;

    await updateInterviewMutation.mutateAsync({
      interview: {
        ...interview,
        name: values.name,
        schedulingLink: values.schedulingLink,
      },
    });

    if (schedulingLinkChanged) {
      amplitude?.logEvent('interview/save scheduling link', {
        'workspace id': workspaceId,
        'job opening id': jobOpeningId,
        'interview id': interviewId,
      });
      posthog?.capture('hire_admin_app:interview/save scheduling link', {
        'workspace id': workspaceId,
        'job opening id': jobOpeningId,
        'interview id': interviewId,
      });
    }

    addNotification({ type: 'saved' });
  };

  return (
    <Formik<Settings>
      enableReinitialize
      initialValues={initialSettings}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ dirty, isSubmitting }) => (
        <Form>
          <PromptIfDirty />

          <DSContentBlock>
            <DSContentBlock.Title>General</DSContentBlock.Title>

            <DSContentBlock.Content>
              <ContentWrapper>
                <TextInputField
                  name="name"
                  label="Interview name"
                  required
                  autoFocus
                />
                <div>
                  <TextInputField
                    name="schedulingLink"
                    label="Scheduling link"
                    placeholder="https://calendly.com/johndoe-1234/45min"
                  />
                  <InfoLink
                    href="https://support.hire.toggl.com/en/articles/9564596-how-to-use-a-scheduling-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    How can I add a scheduling link?
                  </InfoLink>
                </div>
              </ContentWrapper>
            </DSContentBlock.Content>

            <DSContentBlock.Footer>
              <DSButton
                type="submit"
                disabled={!canUpdate || !dirty || isSubmitting}
                data-rh={!canUpdate ? 'Only admins can save changes' : null}
              >
                {isSubmitting ? 'Saving...' : 'Save changes'}
              </DSButton>
            </DSContentBlock.Footer>
          </DSContentBlock>
        </Form>
      )}
    </Formik>
  );
}

const ContentWrapper = styled.div`
  display: grid;
  gap: 16px;
`;

const TextInputField = styled(FormikInputField)`
  max-width: 364px;
`;

const InfoLink = styled.a`
  margin-top: 4px;
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.typography.colorSecondary};
  text-decoration: underline;
`;
