import React, { useCallback, useEffect, useState } from 'react';

import { userHasConsentedToPreferences } from '@/features/gdpr/utils/permissions';
import { IntercomLogo } from '@/features/intercom/components/ui/intercom-logo';
import { UnavailableModal } from '@/features/intercom/components/ui/unavailable-modal';
import { IMe, useMeQuery } from '@/features/me';
import { isUserInSupportMode } from '@/features/me/utils/me';
import { useDetectDeadClicks } from '@/hooks/deadClicks';
import { useDetectRageClicks } from '@/hooks/rageClicks';
import { useLocation } from '@/hooks/router';

import {
  bootIntercom,
  shutdownIntercom,
  trackIntercomEvent,
  updateIntercom,
} from '../utils';

const isIntercomEnabled = (me: IMe) => {
  return !isUserInSupportMode(me) && me.isEmailVerified;
};

export function Intercom() {
  const location = useLocation();
  const meQuery = useMeQuery();

  const [isIntercomAvailable, setIsIntercomAvailable] = useState(
    !!window.Intercom
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleIntercomLoad = useCallback(() => {
    setIsIntercomAvailable(true);
  }, []);

  useEffect(() => {
    window.addEventListener('intercom-loaded', handleIntercomLoad);
    return () =>
      window.removeEventListener('intercom-loaded', handleIntercomLoad);
  }, [handleIntercomLoad]);

  useDetectRageClicks(
    (target) => {
      trackIntercomEvent('rage_click', {
        target: target,
      });
    },
    {
      interval: 750,
      limit: 3,
    }
  );

  useDetectDeadClicks(
    (target) => {
      trackIntercomEvent('dead_click', {
        target: target,
      });
    },
    {
      interval: 1000,
      limit: 2,
    }
  );

  useEffect(() => {
    if (!isIntercomAvailable || !userHasConsentedToPreferences()) return;

    if (
      meQuery.isSuccess &&
      meQuery.data.intercomUserHash != null &&
      isIntercomEnabled(meQuery.data)
    ) {
      bootIntercom(meQuery.data);
    } else {
      shutdownIntercom();
    }
  }, [isIntercomAvailable, meQuery.data, meQuery.isSuccess]);

  useEffect(() => {
    updateIntercom();
  }, [location.pathname]);

  if (!userHasConsentedToPreferences()) {
    return (
      <>
        <IntercomLogo onClick={() => setIsModalOpen(true)} />
        <UnavailableModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </>
    );
  }

  return null;
}
