import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { LinkButton } from '@hundred5/design-system';

import { ConfirmModal } from '@/features/common';
import { GoogleRecaptchaPrivacyDisclaimer } from '@/features/google-recaptcha';
import { useMeQuery } from '@/features/me';
import { useDeleteMe, useExportMe } from '@/hooks/me';

export function ProfileActions() {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [exportMe, exporting] = useExportMe();
  const deleteMe = useDeleteMe();
  const meQuery = useMeQuery();

  const isOwnerOfAnyWorkspace = useMemo(
    () =>
      meQuery.data?.workspaces?.some((workspace) => workspace.role === 'owner'),
    [meQuery.data?.workspaces]
  );

  const openDeleteModal = useCallback(() => setDeleteModalOpen(true), []);
  const closeDeleteModal = useCallback(() => setDeleteModalOpen(false), []);

  return (
    <ProfileActionsContainer>
      <LinkButton onClick={() => !exporting && exportMe()}>
        {exporting ? 'Exporting...' : 'Export profile data'}
      </LinkButton>
      <GoogleRecaptchaPrivacyDisclaimer />
      {!isOwnerOfAnyWorkspace ? (
        <>
          <LinkButton onClick={openDeleteModal}>Delete profile</LinkButton>

          <ConfirmModal
            open={deleteModalOpen}
            action="Delete"
            confirmation="delete"
            onConfirm={deleteMe}
            onClose={closeDeleteModal}
            footerVariant="destructive-primary"
            title="Delete your user account"
          >
            <p>
              Are you sure you want to delete your <strong>user account</strong>
              ?
            </p>
            <p>You will not be able to restore your account in the future.</p>
          </ConfirmModal>
        </>
      ) : null}
    </ProfileActionsContainer>
  );
}

const ProfileActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
