import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import homeworkIllustration from './assets/homework.png';
import quizIllustration from './assets/quiz.png';
import videoIllustration from './assets/video.png';

export const ONBOARDING_TEST_MODAL_COPIES = {
  quiz: {
    title: 'Set up your skills test',
    description:
      'Configure this test to your needs and begin assessing candidates',
    list: [
      {
        icon: regular('circle-check'),
        title: 'Customize questions',
        description:
          'Review and customize test questions to match your requirements',
      },
      {
        icon: regular('gear'),
        title: 'Adjust settings',
        description:
          'Decide the test duration, passing score, and other settings',
      },
      {
        icon: regular('user'),
        title: 'Candidate experience',
        description:
          'Add your branding to the test landing page and results page',
      },
      {
        icon: regular('play'),
        title: 'Preview test',
        description:
          'Try the test yourself to ensure everything is set up correctly',
      },
    ],
    illustration: quizIllustration,
  },
  video: {
    title: 'Set up your async interview',
    description:
      'Configure this async interview and begin assessing candidates by video',
    list: [
      {
        icon: regular('circle-check'),
        title: 'Customize questions',
        description:
          'Review and customize up to 3 test questions to match your requirements',
      },
      {
        icon: regular('video'),
        title: 'Record an intro video',
        description:
          'Add a personal touch by setting up a video introducing yourself and the role',
      },
      {
        icon: regular('user'),
        title: 'Candidate experience',
        description:
          'Add your branding to the test landing page and results page',
      },
      {
        icon: regular('play'),
        title: 'Preview the questions',
        description:
          'Try the async interview yourself to ensure everything is set up correctly',
      },
    ],
    illustration: videoIllustration,
  },
  homework: {
    title: 'Set up your homework',
    description:
      'Customize this assessment and measure candidates on job-related tasks',
    list: [
      {
        icon: regular('circle-check'),
        title: 'Customize questions',
        description:
          'Review and customize up to 3 test questions to match your requirements',
      },
      {
        icon: regular('gear'),
        title: 'Adjust settings',
        description: 'Decide on the duration and add evaluation guidance',
      },
      {
        icon: regular('user'),
        title: 'Candidate experience',
        description:
          'Add your branding to the test landing page and results page',
      },
      {
        icon: regular('play'),
        title: 'Preview homework',
        description:
          'Try the homework yourself to ensure everything is set up correctly',
      },
    ],
    illustration: homeworkIllustration,
  },
};
