import React from 'react';
import styled from '@emotion/styled';

import {
  CustomTextEditor,
  EditorState,
} from '@/features/common/components/custom-text-editor';
import { IValidationResult } from '@/features/test';

import { ValidationAlert } from './validation-alert';

interface QuestionDescriptionEditorProps {
  description: EditorState;
  onChange: (state: EditorState) => void;
  validationResults: IValidationResult[];
}

export const QuestionDescriptionEditor = ({
  description,
  onChange,
  validationResults,
}: QuestionDescriptionEditorProps) => (
  <Wrapper>
    <ValidationAlert fields={['description']} results={validationResults} />

    <CustomTextEditor
      placeholder="Write your question here..."
      state={description}
      onChange={onChange}
      resizable={false}
      minHeight="auto"
    />
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 24px;
  display: grid;
  gap: 24px;
`;
