import postHog from 'posthog-js';

import { userHasConsentedToStatistics } from '@/features/gdpr/utils/permissions';

let posthogLoaded = false;
function initPosthog() {
  if (
    !posthogLoaded &&
    import.meta.env.VITE_POSTHOG_API_KEY &&
    import.meta.env.VITE_POSTHOG_HOST &&
    userHasConsentedToStatistics()
  )
    try {
      postHog?.init(import.meta.env.VITE_POSTHOG_API_KEY, {
        api_host: import.meta.env.VITE_POSTHOG_HOST,
        autocapture: false,
        session_recording: {
          maskTextSelector: '*',
        },
      }) || undefined;
      posthogLoaded = true;
    } catch {
      // pass
    }
}

initPosthog();

// only initialize Posthog when Cookiebot is ready
// and if the user has consented to statistics
window.addEventListener('CookiebotOnConsentReady', initPosthog);

export default postHog;
