import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';

interface UnavailableModalProps {
  open: boolean;
  onClose: () => void;
}

export function UnavailableModal({ onClose, open }: UnavailableModalProps) {
  const handleChangeCookies = () => {
    window.Cookiebot.show();
    onClose();
  };

  return (
    <DSModal fluid open={open} onClose={onClose}>
      <Container data-testid="confirm-modal">
        <Header>
          <Title>Intercom is disabled</Title>
          <DSModal.Separator />
        </Header>
        <Content>
          <p>
            We use Intercom to provide in-app support. To use Intercom, you need
            to update your cookie preferences. Please click the button below to
            give consent for Intercom cookies and start a support chat.
          </p>
          <p>
            Alternatively you can{' '}
            <a href={`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`}>
              contact us via email
            </a>
            .
          </p>
        </Content>
        <Footer float="right">
          <DSButton variant="secondary" onClick={onClose}>
            Close
          </DSButton>
          <DSButton
            data-testid="confirm-modal-action-button"
            onClick={handleChangeCookies}
          >
            Update cookie preferences
          </DSButton>
        </Footer>
      </Container>
    </DSModal>
  );
}

const Container = styled.div`
  max-width: 480px;
`;

const Header = styled.header`
  color: ${(props) => props.theme.typography.colorPrimary};
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;

  @media all and (max-width: 768px) {
    padding-top: 8px;
  }
`;

const Title = styled.h2`
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  padding-bottom: 16px;
`;

const Content = styled.div`
  color: ${(props) => props.theme.typography.colorPrimary};
  font-size: 14px;
`;

const Footer = styled(DSModal.Footer)`
  display: flex;
  gap: 8px;

  @media all and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
