import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';

interface ExpandCollapseProps {
  collapsed: boolean;
  onClick: () => void;
}

export function ExpandCollapse({ collapsed, onClick }: ExpandCollapseProps) {
  return (
    <ExpandCollapseContainer
      type="button"
      onClick={onClick}
      data-navigation-tooltip={collapsed ? 'Open sidebar' : 'Close sidebar'}
      data-navigation-tooltip-at="right"
    >
      <Icon
        icon={collapsed ? regular('chevrons-right') : regular('chevrons-left')}
        color="purple-60"
        fontSize="14px"
      />
    </ExpandCollapseContainer>
  );
}

const ExpandCollapseContainer = styled.button`
  padding: 0;
  margin-top: 22px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 100%;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    background-color: ${({ theme }) => theme.colors.purple[100]};
  }
`;
