import * as React from 'react';

export const CreateWorkspacePage = React.lazy(
  () => import('@/pages/create-workspace-page')
);

export const LogInPage = React.lazy(
  () => import('@/pages/authentication/login-page')
);

export const EmailVerificationMessagePage = React.lazy(
  () => import('@/pages/email-verification-message-page')
);

export const EmailVerificationPage = React.lazy(
  () => import('@/pages/authentication/email-verification-page')
);

export const AccountSettingsPage = React.lazy(
  () => import('@/pages/account-settings-page')
);

export const OnboardingFormPage = React.lazy(
  () => import('@/pages/onboarding-form-page')
);
