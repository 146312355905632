import { invert } from 'lodash';

import api from '@/api/api';

import { PreferenceName, Preferences } from '../types/preferences';

const storageKeys: { [K in PreferenceName]: string } = {
  bookmarkedJobOpenings: 'hundred5_bookmarked_job_openings',
  closedActivityFeedChecklist: 'hundred5_closed_activity_feed_checklist',
  closedActivityFeedWelcomeMessage:
    'hundred5_closed_activity_feed_welcome_message',
  closedAnnouncements: 'hundred5_closed_announcements',
  closedProTips: 'hundred5_closed_pro_tips',
  closedTemplateGalleryBanner: 'hundred5_closed_template_gallery_banner',
  feedbackClosed: 'hundred5_feedback_closed',
  selectedEmailTemplatesByTypeAndTestId:
    'hundred5_selected_email_templates_by_type_and_test_id',
  selectedWorkspace: 'hundred5_selected_workspace',
  closedRecommendedCandidatesInJobOpenings:
    'hundred5_closed_recommended_candidates_in_job_openings',
  testOnboardingOpened: 'test_onboarding_opened',
};

const invertedStorageKeys = invert(storageKeys);

const defaultPreferences: Preferences = {
  feedbackClosed: null,
  closedProTips: [],
  closedAnnouncements: [],
  selectedWorkspace: null,
  selectedEmailTemplatesByTypeAndTestId: {},
  bookmarkedJobOpenings: [],
  closedTemplateGalleryBanner: false,
  closedActivityFeedWelcomeMessage: false,
  closedActivityFeedChecklist: false,
  closedRecommendedCandidatesInJobOpenings: {},
  testOnboardingOpened: {},
};

export async function fetchPreferences(): Promise<Preferences> {
  try {
    const response = await api.get('/storage');

    const mergedPreferences = Object.entries(response.data).reduce(
      (preferences, [key, value]) => {
        return {
          ...preferences,
          [invertedStorageKeys[key]]:
            key === 'hundred5_selected_workspace' ? String(value) : value,
        };
      },
      { ...defaultPreferences }
    );

    return mergedPreferences;
  } catch (e) {
    return defaultPreferences;
  }
}

export async function updatePreference({
  name,
  value,
}: {
  name: PreferenceName;
  value: any;
}): Promise<void> {
  const key = storageKeys[name];

  if (name === 'selectedWorkspace') {
    value = parseInt(value, 10);
  }

  await api.put(`/storage/${key}`, JSON.stringify(value), {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  });
}
