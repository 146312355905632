import React from 'react';
import styled from '@emotion/styled';

export function ConnectedEmail({ email }: { email: string }) {
  return <Container>Connected to {email}</Container>;
}

const Container = styled.div`
  border-radius: 8px;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.purple[5]};
  grid-area: email;
  width: fit-content;
`;
