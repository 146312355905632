import React from 'react';
import styled from '@emotion/styled';

import { NavigationListItem } from './navigation-list-item';

interface NavigationListProps {
  children: React.ReactNode;
}

export function NavigationList({ children }: NavigationListProps) {
  return <List>{children}</List>;
}

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

NavigationList.Item = NavigationListItem;
