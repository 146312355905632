import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';

import {
  ECancelSubscribeStep,
  ICancelModalStepProps,
} from '@/features/billing';

import {
  CancelModalContentContainer,
  CancelModalFooterButtons,
  CancelModalHeader,
} from '.';

export const InitialCancelStepModal = ({
  selectedBillingPlan,
  open,
  setCurrentStep,
}: ICancelModalStepProps) => {
  return (
    <DSModal
      fluid
      open={open}
      onClose={() => setCurrentStep(undefined)}
      contentStyle={{ width: '640px' }}
    >
      <CancelModalHeader>
        {selectedBillingPlan?.plan === 'free'
          ? 'Downgrade to Free'
          : 'Downgrade to Starter'}
        <DSModal.CloseButton onClick={() => setCurrentStep(undefined)} />
      </CancelModalHeader>
      <DSModal.Separator />
      <CancelModalContentContainer data-testid="cancel-subscription-initial-modal">
        {selectedBillingPlan?.plan === 'free' ? (
          <>
            On the Free plan you can only have <b>one active job opening.</b>
            <br></br>
            <br></br>
            At the end of your current billing cycle, your job openings will be
            closed and candidates will no longer be able to apply. You can then
            choose one job opening to reopen.{' '}
          </>
        ) : (
          <>
            On the Starter plan you can only have <b>3 active job openings.</b>
            <br></br>
            At the end of your current billing cycle, your 3 most recent job
            openings will remain active and all others will be closed.
            Candidates will no longer be able to apply to the closed jobs.{' '}
          </>
        )}
        <Note>
          {selectedBillingPlan?.plan === 'free' ? (
            <>
              <b>Note:</b> on the Free plan you can only access candidate data
              for 30 days.
            </>
          ) : (
            <>
              <b>Note:</b> if you would like to change which job opening is
              active you can close a job and reopen another.
            </>
          )}
        </Note>
        <DSModal.Footer>
          <CancelModalFooterButtons>
            <DSButton
              variant="secondary"
              type="button"
              onClick={() => setCurrentStep(undefined)}
            >
              Stay on current plan
            </DSButton>
            <DSButton
              variant="primary-purple"
              type="button"
              onClick={() => setCurrentStep(ECancelSubscribeStep.FinalCancel)}
            >
              {selectedBillingPlan?.plan === 'free'
                ? 'Downgrade to Free'
                : 'Downgrade to Starter'}
            </DSButton>
          </CancelModalFooterButtons>
        </DSModal.Footer>
      </CancelModalContentContainer>
    </DSModal>
  );
};

const Note = styled.div`
  margin-top: 12px;
  padding: 12px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.purple[100]};
  background: ${({ theme }) => theme.colors.purple[5]};
  font-weight: 500;
  font-size: 14px;
`;
