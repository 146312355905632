import styled from '@emotion/styled';

export const Button = styled.button<{ active: boolean }>`
  position: absolute;
  right: 16px;
  top: 5px;
  display: none; // controlled by parent
  align-items: center;
  justify-content: center;
  height: 28px;
  padding: 0;
  width: 28px;
  cursor: pointer;
  pointer-events: all;
  border: none;
  border-radius: 8px;
  background: transparent;

  &:hover {
    background-color: ${({ active, theme }) =>
      active ? theme.colors.orange[110] : theme.colors.purple[95]};
  }
`;
