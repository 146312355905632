import React, { createContext, useCallback, useState } from 'react';

import { TGenericPricingUpsellTypes } from './generic-pricing-upsell-modal.types';

export const GenericPricingUpsellModalContext = createContext<{
  isOpen: boolean;
  openPricingUpsell: (
    type: TGenericPricingUpsellTypes,
    plan?: 'free' | 'starter' | 'premium' | undefined
  ) => void;
  closePricingUpsell: () => void;
  type:
    | 'jobs'
    | 'generic'
    | 'unlimitedSkillsTests'
    | 'fullCustomization'
    | 'changePlan'
    | 'automation';
  plan: 'free' | 'starter' | 'premium' | undefined;
}>({
  isOpen: false,
  openPricingUpsell: (type, plan) => {},
  closePricingUpsell: () => {},
  type: 'generic',
  plan: undefined,
});

export function GenericPricingUpsellModalContextProvider({ children }) {
  const [genericPricingUpsellModalData, setGenericPricingUpsellModalData] =
    useState<{
      isOpen: boolean;
      type: 'jobs' | 'generic' | 'unlimitedSkillsTests' | 'fullCustomization';
      plan: 'free' | 'starter' | 'premium' | undefined;
    }>({
      isOpen: false,
      type: 'generic',
      plan: undefined,
    });

  const openPricingUpsell = useCallback(
    (type, plan) => {
      setGenericPricingUpsellModalData({
        isOpen: true,
        type: type,
        plan: plan,
      });
    },
    [setGenericPricingUpsellModalData]
  );
  const closePricingUpsell = useCallback(() => {
    setGenericPricingUpsellModalData((prev) => ({
      isOpen: false,
      type: prev.type,
      plan: undefined,
    }));
  }, [setGenericPricingUpsellModalData]);

  return (
    <GenericPricingUpsellModalContext.Provider
      value={{
        isOpen: genericPricingUpsellModalData.isOpen,
        type: genericPricingUpsellModalData.type,
        openPricingUpsell,
        closePricingUpsell,
        plan: genericPricingUpsellModalData.plan,
      }}
    >
      {children}
    </GenericPricingUpsellModalContext.Provider>
  );
}
