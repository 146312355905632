import smartlookClient from 'smartlook-client';

import { userHasConsentedToStatistics } from '@/features/gdpr/utils/permissions';

function loadSmartlook() {
  if (
    import.meta.env.VITE_SMARTLOOK_PROJECT_KEY &&
    userHasConsentedToStatistics() &&
    !smartlookClient.initialized()
  ) {
    try {
      smartlookClient.init(import.meta.env.VITE_SMARTLOOK_PROJECT_KEY);
    } catch {
      //pass
    }
  }
}

// try to load smartlook script when the page loads
// If cookiebot is not available we add an event listener
loadSmartlook();

// only initialize Smartlook when Cookiebot is ready
// and if the user has consented to statistics
window.addEventListener('CookiebotOnConsentReady', loadSmartlook);

export const trackSmartlookEvent = (
  event: string,
  data?: { [key: string]: string | number | boolean }
) => {
  if (!smartlookClient.initialized() || !userHasConsentedToStatistics()) return;
  smartlookClient.track(event, { ...data });
};
