import React from 'react';
import styled from '@emotion/styled';
import { useMedia } from 'react-use';

export function MobileBanner() {
  const isMobile = useMedia('(max-width: 768px)');

  return isMobile ? (
    <MobileBannerContainer>
      <h3>Discover more on desktop!</h3>
      <p>
        Switch to our desktop web app for advanced features and real-time
        collaboration.
      </p>
    </MobileBannerContainer>
  ) : null;
}

const MobileBannerContainer = styled.section`
  background: ${({ theme }) => theme.colors.purple[90]};
  border-radius: 8px;
  padding: 12px;
  margin: auto 16px 0 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  h3 {
    color: ${({ theme }) => theme.colors.white};
    font-size: 12px;
    font-weight: 500;
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.colors.white};
    font-size: 12px;
    font-weight: 400;
    margin: 0;
  }
`;
