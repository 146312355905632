import { userHasConsentedToStatistics } from '@/features/gdpr/utils/permissions';
import { sendIntercomMessage } from '@/features/intercom';

const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL;

/**
 * Contact support through intercom chat (if enabled) or email
 */
export const contactSupport = () => {
  if (!!window.Intercom && userHasConsentedToStatistics()) {
    sendIntercomMessage();
  } else {
    window.open(`mailto:${supportEmail}`, '_blank');
  }
};
