import React, { useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { usePostHog } from 'posthog-js/react';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { ICandidateTag } from '@/features/candidate';
import { Autocomplete, Icon, isTagRestricted } from '@/features/common';
import { useNotifications } from '@/features/notifications';
import { useWorkspaceId } from '@/hooks/router';

interface AddTagProps {
  suggestions: ICandidateTag[] | undefined;
  tagsExist: boolean;
  onAdd: (name: string) => void;
}

export function AddTag(props: AddTagProps) {
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = useState('');
  const { addNotification } = useNotifications();
  const workspaceId = useWorkspaceId();
  const posthog = usePostHog();

  const handleActivate = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.stopPropagation();
    }

    setValue('');
    setIsActive(true);

    const amplitude = getAmplitudeClient();
    amplitude?.logEvent('added tag', {
      'workspace id': workspaceId,
    });
    posthog?.capture('hire_admin_app:added tag', {
      'workspace id': workspaceId,
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target) {
      setValue(event.target.value);
    }
  };

  const handleClickOutside = () => {
    setIsActive(false);
  };

  const addTag = (tagValue?: string) => {
    const cleanValue = tagValue ? tagValue.trim() : value.trim();

    if (cleanValue) {
      if (cleanValue.length > 20) {
        addNotification({
          type: 'tag_length_exceeded',
          meta: { maxLength: 20 },
        });
      } else if (isTagRestricted(cleanValue)) {
        addNotification({
          type: 'tag_restricted',
          meta: { tag: cleanValue },
        });
      } else {
        props.onAdd(cleanValue);
      }
    }

    setIsActive(false);
    setValue('');
  };

  const suggestionsWithRestrictedItems =
    props.suggestions?.map((tag) => ({
      id: tag.id,
      value: tag.name,
      locked: isTagRestricted(tag.name.trim()),
    })) || [];

  return (
    <AddTagContainer>
      <AutocompleteWrapper isVisible={isActive}>
        <Autocomplete
          suggestions={suggestionsWithRestrictedItems}
          onSuggestionSelect={addTag}
          isActive={isActive}
          value={value}
          onChange={handleInputChange}
          onClickOutside={handleClickOutside}
          maxInputLength={20}
        />
      </AutocompleteWrapper>

      <AddTagButton
        isVisible={!isActive}
        hideTitle={props.tagsExist}
        onClick={handleActivate}
      >
        <Icon icon={regular('plus')} />
        <AddTagButtonLabel>Add Tag</AddTagButtonLabel>
      </AddTagButton>
    </AddTagContainer>
  );
}

const AddTagContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
`;

const AutocompleteWrapper = styled.div<{ isVisible: boolean }>`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
`;

const AddTagButton = styled.button<{ isVisible: boolean; hideTitle: boolean }>`
  font-weight: 500;
  font-size: 12px;
  padding: 3px 12px;
  background: #ffffff;
  border: 1px solid #aba0af;
  border-radius: 8px;
  min-height: 24px;
  height: 24px;
  cursor: pointer;
  transition: background-color 0.1s;
  display: ${(props) => (props.isVisible ? 'inline-flex' : 'none')};
  align-items: center;

  :hover {
    background: ${(props) => props.theme.hover.onWhite};
  }

  ${(props) =>
    props.hideTitle === true &&
    `
      padding-left: 6px;
      padding-right: 6px;

      ${AddTagButtonLabel} {
        display: none;
      }
  `}
`;

const AddTagButtonLabel = styled.span`
  display: inline-block;
  margin-left: 5px;
`;
