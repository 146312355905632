import React from 'react';
import styled from '@emotion/styled';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';

export function Help() {
  return (
    <HelpContainer
      href="https://support.hire.toggl.com/"
      target="_blank"
      rel="noreferrer noopener"
      data-navigation-tooltip="Advice and answers"
      data-navigation-tooltip-at="right"
    >
      <Icon icon={solid('circle-question')} color="purple-60" fontSize="14px" />
    </HelpContainer>
  );
}

const HelpContainer = styled.a`
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    background-color: ${({ theme }) => theme.colors.purple[100]};
  }
`;
