import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { ME_QUERY_KEY } from '@/features/me';
import {
  deleteWorkspaceUser,
  fetchWorkspaceUsers,
  updateWorkspaceUser,
} from '@/features/workspace';
import { useWorkspaceIdOrNull } from '@/hooks/router';
import { isSupportEnv } from '@/utils/env';

export const WORKSPACE_USERS_QUERY_KEY = ['workspace', 'users'];

export const useWorkspaceUsersQuery = (options?: UseQueryOptions) => {
  const workspaceId = useWorkspaceIdOrNull();
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(WORKSPACE_USERS_QUERY_KEY, {
    onSuccess: () =>
      queryClient.invalidateQueries([
        ...WORKSPACE_USERS_QUERY_KEY,
        workspaceId,
      ]),
  });

  return useQuery(
    [...WORKSPACE_USERS_QUERY_KEY, workspaceId],
    () => fetchWorkspaceUsers({ workspaceId: workspaceId! }),
    {
      enabled: Boolean(workspaceId) && !isSupportEnv(),
      ...(options as any),
      placeholderData: () => [],
    }
  );
};

export const useUpdateWorkspaceUserMutation = (
  options?: UseMutationOptions
) => {
  return useMutation(updateWorkspaceUser, {
    mutationKey: WORKSPACE_USERS_QUERY_KEY,
    ...(options as any),
  });
};

export const useDeleteWorkspaceUserMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceIdOrNull();

  return useMutation(deleteWorkspaceUser, {
    mutationKey: WORKSPACE_USERS_QUERY_KEY,
    onSuccess: async () => {
      await queryClient.invalidateQueries([...ME_QUERY_KEY]);
      await queryClient.invalidateQueries([
        ...WORKSPACE_USERS_QUERY_KEY,
        workspaceId,
      ]);
    },
    ...(options as any),
  });
};
