import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSContentBlock } from '@hundred5/design-system';

import { TId } from '@/features/common';
import {
  TScreenType,
  useTestQuery,
  useUpdateTestMutation,
} from '@/features/test';

interface RedirectBlockProps {
  redirectUrl: string;
  testId: TId;
  screenType: TScreenType;
}

export function RedirectBlock({
  redirectUrl,
  testId,
  screenType,
}: RedirectBlockProps) {
  const testQuery = useTestQuery({ testId });
  const updateTestMutation = useUpdateTestMutation();
  const test = testQuery.data;

  const handleDisableRedirect = async () => {
    if (test) {
      const updatedTest = {
        screens: {
          ...test.screens,
          [screenType]: {
            settings: {
              ...testQuery.data.screens[screenType]?.settings,
              redirectUrl: '',
              redirectUrlEnabled: false,
            },
            content: testQuery.data.screens[screenType]?.content,
          },
        },
      };

      await updateTestMutation.mutateAsync({ testId, test: updatedTest });
    }
  };

  return (
    <Wrapper>
      <RedirectBlockContainer>
        <DSContentBlock.Title>Redirect URL</DSContentBlock.Title>
        <DSContentBlock.Content>
          Candidates are redirected to{' '}
          <Link href={redirectUrl} rel="noreferrer noopener" target="_blank">
            {redirectUrl}
          </Link>{' '}
          after submitting the test.
          <ButtonContainer>
            <DSButton variant="secondary" onClick={handleDisableRedirect}>
              Disable
            </DSButton>
          </ButtonContainer>
        </DSContentBlock.Content>
      </RedirectBlockContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.purple[10]};
  border-radius: 8px;
  display: grid;
  // 40px - modal paddings, 117px - header height, 52px - content paddings, 52px - editing toolbar height
  min-height: calc(100vh - (80px + 117px + 52px + 52px));
`;

const RedirectBlockContainer = styled(DSContentBlock)`
  width: 576px;
  margin: 36px;
  place-self: center;
`;

const Link = styled.a`
  text-decoration: underline;
  color: inherit;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 48px;
`;
