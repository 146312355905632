import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { uniqueId } from 'lodash';

import { TId } from '@/features/common';
import {
  INotification,
  NOTIFICATION_DEFAULTS,
  TNotificationContent,
  TNotificationInput,
  useLogNotification,
} from '@/features/notifications';

interface INotificationContext {
  notifications: INotification[];
  addNotification(notification: TNotificationInput): void;
  removeNotification(id: TId): void;
}

const NotificationContext = createContext<INotificationContext>({
  notifications: [],
  addNotification: () => {},
  removeNotification: () => {},
});

export function NotificationProvider({ children }: { children: ReactNode }) {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const { logNewNotification } = useLogNotification();
  const addNotification = useCallback(
    (notification: TNotificationInput) => {
      const defaults = NOTIFICATION_DEFAULTS[notification.type];
      const mergedContent = {
        ...defaults,
        ...notification,
      } as TNotificationContent;

      const newNotification: INotification = {
        id: uniqueId('notification'),
        content: mergedContent,
      };

      setNotifications((prevNotifications) => {
        if (!newNotification.content.stackable) {
          // Don't add the notification if it's type already exists
          if (
            prevNotifications.some((n) => n.content.type === notification.type)
          ) {
            return prevNotifications;
          }
        }

        logNewNotification(newNotification);

        return [...prevNotifications, newNotification];
      });
    },
    [logNewNotification]
  );

  const removeNotification = useCallback((id: TId) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((n) => n.id !== id)
    );
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        addNotification,
        removeNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export function useNotifications(): INotificationContext {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      'useNotifications must be used within a NotificationContext.Provider'
    );
  }
  return context;
}
