import React from 'react';
import styled from '@emotion/styled';
import { DSField, DSTextArea } from '@hundred5/design-system';
import { useField } from 'formik';
import { uniqueId } from 'lodash';

type Props = {
  name: string;
  label?: React.ReactNode;
  required?: boolean;
  id?: string;
  placeholder?: string;
  readonly?: boolean;
  disabled?: boolean;
  errorAbsolute?: boolean;
  inputHeight?: string;
  showLengthCounter?: boolean;
} & React.ComponentProps<typeof DSTextArea>;

export function FormikTextAreaField({
  name,
  label,
  required,
  id = uniqueId('formik-field-'),
  placeholder,
  readonly,
  disabled,
  errorAbsolute,
  inputHeight,
  showLengthCounter,
  ...props
}: Props) {
  const [field, meta] = useField<any>(name);
  const error = meta.touched && !!meta.error ? meta.error : null;

  return (
    <DSField
      for={id}
      label={label}
      required={required}
      error={error}
      errorAbsolute={errorAbsolute}
    >
      <DSTextArea
        id={id}
        placeholder={placeholder}
        error={!!error}
        {...props}
        {...field}
        readOnly={readonly}
        disabled={disabled}
        height={inputHeight}
      />
      {showLengthCounter && !error && field.value.length > 0 && (
        <LengthCounter>
          {field.value.length}
          {props.maxLength ? ` of ${props.maxLength} ` : ' '}
          characters used
        </LengthCounter>
      )}
    </DSField>
  );
}

const LengthCounter = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: ${(props) => props.theme.typography.colorPrimary};
  margin-top: 4px;
  position: absolute;
  top: 100%;
`;
