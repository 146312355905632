import React from 'react';
import styled from '@emotion/styled';

import { PageHeader, useDocumentTitle } from '@/features/common';

const AccountSSOSettingsPage = () => {
  useDocumentTitle('Account settings');

  const params = new URLSearchParams({
    product: 'hire',
    webapp: 'true',
    theme: 'light',
    hide_header: 'true',
  });

  return (
    <>
      <PageHeader
        title="Single sign-on (SSO)"
        backButton={{
          to: `/settings/profile`,
          tooltip: 'Go back to profile settings',
        }}
        sticky
      />
      <Iframe
        src={`${import.meta.env.VITE_TOGGL_ACCOUNTS_URL}/settings/sso?${params.toString()}`}
      />
    </>
  );
};

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

export default AccountSSOSettingsPage;
