import { addDays } from 'date-fns/addDays';
import { decamelizeKeys } from 'humps';
import { pick } from 'lodash';
import qs from 'qs';

import api from '@/api/api';
import { ICandidateTestState } from '@/features/candidate';
import {
  ICandidateSearchParams,
  TSortOrder,
} from '@/features/candidate-search';
import { TId } from '@/features/common/types';
import { TJobOpeningVisibility } from '@/features/job-opening';
import {
  IWorkspaceCandidate,
  IWorkspaceCandidateTest,
} from '@/features/workspace';
import { parseAPIData } from '@/utils/parsers';

type TAPIOrderBy =
  | 'created_at'
  | 'full_name'
  | 'job_name'
  | 'latest_score_percent' // candidates list
  | 'overall_score_as_percents' // pipeline candidates
  | 'rating'
  | 'has_notes'
  | 'flags'
  | 'tags'
  | 'country'
  | 'stage';

interface IApiSearchParams {
  hasNotes?: boolean;
  ratings?: string[];
  minScorePercentage?: string;
  tagIds?: string[];
  hasTags?: boolean;
  minCreatedAt?: string;
  maxCreatedAt?: string;
  isAboveThreshold?: boolean;
  isSuspicious?: boolean;
  query?: string;
  actionRequired?: boolean;
  limit: string;
  offset: string;
  orderBy: TAPIOrderBy;
  orderByDirection: 'asc' | 'desc';
  countries?: string[];
  states?: string[];
  noRejected?: boolean;
}

interface IApiWorkspaceCandidateTest {
  id: number;
  overallScoreAsPercents: number;
  originalPercentToPass: number;
  percentToPass: number;
  state: string;
  testId: number;
  testName: string;
}

interface IAPIWorkspaceCandidate {
  id: number;
  createdAt: string;
  hiredAt: string | null;
  latestTestFinishedAt: string | null;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  name: string;
  hasNotes: boolean;
  isSuspicious: boolean;
  rating: number;
  latestScorePercent: number;
  latestPercentToPass: number;
  jobOpenings: {
    id: number;
    name: string;
    visibility: TJobOpeningVisibility;
  }[];
  tags: { id: number; name: string }[] | null;
  country?: string;
  tests?: IApiWorkspaceCandidateTest[];
  testTakerCategoryId?: number;
  isUnlocked: boolean;
}

const getOrderBy = (sortOrder: TSortOrder): TAPIOrderBy => {
  switch (sortOrder) {
    case 'date':
      return 'created_at';
    case 'name':
      return 'full_name';
    case 'job':
      return 'job_name';
    case 'notes':
      return 'has_notes';
    case 'stars':
      return 'rating';
    case 'scores': // candidates list
      return 'latest_score_percent';
    case 'overallScore': // pipeline candidates
      return 'overall_score_as_percents';
    case 'flags':
      return 'flags';
    case 'tags':
      return 'tags';
    case 'country':
      return 'country';
    case 'stage':
      return 'stage';

    default:
      return 'full_name';
  }
};

export const serializeSearchParams = (
  searchParams: ICandidateSearchParams
): IApiSearchParams => {
  return {
    hasNotes: searchParams.notes ? searchParams.notes === 'yes' : undefined,
    ratings: searchParams.stars,
    minScorePercentage:
      searchParams.score && searchParams.score !== 'over_threshold'
        ? searchParams.score
        : undefined,
    tagIds: searchParams.tags?.filter((tagId) => tagId !== '0'),
    hasTags: searchParams.tags?.includes('0') ? false : undefined,
    isAboveThreshold: searchParams.score === 'over_threshold' || undefined,
    isSuspicious: searchParams.suspicious === 'yes' || undefined,
    query: searchParams.q,
    limit: searchParams.itemsPerPage,
    minCreatedAt:
      searchParams.date &&
      new Date(searchParams.date.split('--')[0]).toISOString(),
    maxCreatedAt:
      searchParams.date &&
      addDays(new Date(searchParams.date.split('--')[1]), 1).toISOString(),
    offset: (
      parseInt(searchParams.page) * parseInt(searchParams.itemsPerPage) -
      parseInt(searchParams.itemsPerPage)
    ).toString(),
    orderBy: getOrderBy(searchParams.sortOrder),
    orderByDirection: searchParams.sortDirection,
    actionRequired: searchParams.actionRequired === 'yes' || undefined,
    countries: searchParams.country || undefined,
    states: searchParams.states?.map((state) =>
      state.toLocaleLowerCase().replace(/ /g, '_')
    ),
    noRejected: searchParams.noRejected,
  };
};
const parseApiCandidateTests = (
  apiTests?: IApiWorkspaceCandidateTest[]
): IWorkspaceCandidateTest[] | undefined => {
  return apiTests?.map((test) => ({
    ...test,
    state: test.state as ICandidateTestState,
  }));
};

const parseWorkspaceCandidate = (
  apiWorkspaceCandidate: IAPIWorkspaceCandidate
): IWorkspaceCandidate => ({
  id: String(apiWorkspaceCandidate.id),
  createdAt: new Date(apiWorkspaceCandidate.createdAt),
  hiredAt: apiWorkspaceCandidate.hiredAt
    ? new Date(apiWorkspaceCandidate.hiredAt)
    : null,
  latestTestFinishedAt: apiWorkspaceCandidate.latestTestFinishedAt
    ? new Date(apiWorkspaceCandidate.latestTestFinishedAt)
    : null,
  suspicious: apiWorkspaceCandidate.isSuspicious,
  jobOpenings: apiWorkspaceCandidate.jobOpenings.map((opening) => ({
    id: String(opening.id),
    name: opening.name,
    visibility: opening.visibility,
  })),
  tags: apiWorkspaceCandidate.tags
    ? apiWorkspaceCandidate.tags.map((tag) => ({
        id: String(tag.id),
        name: tag.name,
      }))
    : null,
  tests: parseApiCandidateTests(apiWorkspaceCandidate.tests),
  testTakerCategoryId: apiWorkspaceCandidate.testTakerCategoryId
    ? String(apiWorkspaceCandidate.testTakerCategoryId)
    : undefined,
  ...pick(apiWorkspaceCandidate, [
    'firstName',
    'lastName',
    'fullName',
    'email',
    'name',
    'hasNotes',
    'rating',
    'latestScorePercent',
    'latestPercentToPass',
    'country',
  ]),
  isUnlocked: apiWorkspaceCandidate.isUnlocked,
});

interface ICandidateList {
  total: number;
  candidates: IWorkspaceCandidate[] | null;
}

export async function fetchWorkspaceCandidates(
  workspaceId: TId,
  searchParams: ICandidateSearchParams
): Promise<ICandidateList> {
  const queryString = qs
    .stringify(
      decamelizeKeys(serializeSearchParams(searchParams), { separator: '-' }),
      {
        encode: false,
        arrayFormat: 'comma',
      }
    )
    .replace('[]', '');

  const response = await api.get(
    `/workspaces/${workspaceId}/candidates?${queryString}`
  );

  const data = parseAPIData(response.data) as {
    total: number;
    testTakers: IAPIWorkspaceCandidate[] | null;
  };

  return {
    total: data.total,
    candidates: data.testTakers?.map(parseWorkspaceCandidate) || null,
  };
}
