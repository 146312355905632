import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSColumn, DSContentBlock } from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { array, object, string } from 'yup';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import {
  FormikCheckboxField,
  FormikComboboxMultipleField,
  FormikRadioField,
  PromptIfDirty,
  useCountryList,
} from '@/features/common';
import {
  IRecommendationSettings,
  useRecommendationSettingsMutation,
  useRecommendationSettingsQuery,
} from '@/features/job-opening';
import { useJobOpeningPermission } from '@/features/permissions';
import { useJobOpeningId, useWorkspaceId } from '@/hooks/router';

const validationSchema = object().shape({
  countryCodes: array().when('enabled', {
    is: true,
    then: () => array().of(string()).min(1, 'This field is mandatory'),
  }),
});

export function JobOpeningSettingsCandidateRecommendations() {
  const canUpdate = useJobOpeningPermission()('job opening', 'update');
  const { countries } = useCountryList(true);
  const jobOpeningId = useJobOpeningId();
  const workspaceId = useWorkspaceId();
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();
  const { data: recommendationSettings } = useRecommendationSettingsQuery({
    jobOpeningId,
  });
  const recommendationSettingsMutation = useRecommendationSettingsMutation({
    jobOpeningId,
  });

  const handleSubmit = async (values: IRecommendationSettings) => {
    await recommendationSettingsMutation.mutateAsync({
      jobOpeningId,
      settings: values,
    });

    amplitude?.logEvent('recommendations filter/save', {
      'workspace id': workspaceId,
      'job opening id': jobOpeningId,
      enabled: values.enabled,
      'country codes': values.countryCodes,
      remote: values.remote,
    });
    posthog?.capture('hire_admin_app:recommendations filter/save', {
      'workspace id': workspaceId,
      'job opening id': jobOpeningId,
      enabled: values.enabled,
      'country codes': values.countryCodes,
      remote: values.remote,
    });
  };

  return (
    <Formik<IRecommendationSettings>
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={{
        enabled: recommendationSettings?.enabled ?? false,
        countryCodes: recommendationSettings?.countryCodes ?? [],
        remote: recommendationSettings?.remote ?? true,
      }}
      validationSchema={validationSchema}
    >
      {({ values, initialValues, dirty, setFieldValue }) => (
        <Form>
          <PromptIfDirty />
          <DSContentBlock>
            <DSContentBlock.Title>
              Candidate recommendations (beta)
            </DSContentBlock.Title>
            <DSContentBlock.Content>
              <Subtitle>Filter candidates based on location</Subtitle>
              <DSColumn gap="16px">
                <OptionRow>
                  <FormikRadioField
                    name="enabled"
                    label="Disabled"
                    disabled={!canUpdate}
                    checked={!values.enabled}
                    onChange={() => {
                      setFieldValue('enabled', false);
                      setFieldValue('countryCodes', []);
                      setFieldValue('remote', true);
                    }}
                  />
                  <FormikRadioField
                    name="enabled"
                    label="Enabled"
                    disabled={!canUpdate}
                    checked={values.enabled}
                    onChange={() => {
                      setFieldValue('enabled', true);
                      setFieldValue('countryCodes', initialValues.countryCodes);
                      setFieldValue('remote', initialValues.remote);
                    }}
                  />
                </OptionRow>

                <FormikComboboxMultipleField
                  name="countryCodes"
                  label="Country"
                  placeholder="Filter by country"
                  items={countries.map((country) => ({
                    id: country.code,
                    label: country.name,
                  }))}
                  required
                  disabledField={!values.enabled}
                  disabledInput={!canUpdate || !values.enabled}
                />

                <FormikCheckboxField
                  name="remote"
                  label="Show candidates open to remote work"
                  disabled={!canUpdate || !values.enabled}
                />
              </DSColumn>
            </DSContentBlock.Content>
            {canUpdate && (
              <DSContentBlock.Footer>
                <DSButton type="submit" disabled={!dirty}>
                  Save changes
                </DSButton>
              </DSContentBlock.Footer>
            )}
          </DSContentBlock>
        </Form>
      )}
    </Formik>
  );
}

const Subtitle = styled.h2`
  font-weight: 700;
  font-size: 14px;
  color: ${(props) => props.theme.typography.colorPrimary};
  margin: 0 0 12px 0;
`;

const OptionRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(max-content, 148px);
  gap: 16px;
`;
