import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import { FormikInputField } from '@/features/common';

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (attachmentType: string) => void;
}

const validationSchema = object().shape({
  attachmentType: string().required("This can't be blank!"),
});

export function AddAttachmentTypeModal(props: Props) {
  const initialValues = { attachmentType: '' };

  return (
    <DSModal fluid contentStyle={{ width: 400 }} open={props.open}>
      <Header>
        Add attachment
        <DSModal.CloseButton onClick={props.onClose} />
      </Header>
      <DSModal.Separator />
      <Formik
        enableReinitialize
        onSubmit={({ attachmentType }) => props.onSave(attachmentType)}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ dirty }) => (
          <Form>
            <Content>
              <FormikInputField
                name="attachmentType"
                label="Name"
                required
                placeholder="Attachment name"
                errorAbsolute
              />
            </Content>
            <DSModal.Footer float="right">
              <DSButton type="submit" disabled={!dirty}>
                Add attachment
              </DSButton>
            </DSModal.Footer>
          </Form>
        )}
      </Formik>
    </DSModal>
  );
}

const Header = styled.div`
  font-weight: 700;
  font-size: ${16 / 14}rem;
  color: ${(props) => props.theme.typography.colorPrimary};
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const Content = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
`;
