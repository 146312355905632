import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { useTestPage } from '@/features/test';

export function useTestPageAnalytics(pageName: string) {
  const { workspace, jobOpening, test } = useTestPage();
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();
  useEffect(() => {
    amplitude?.logEvent(`page view/open ${pageName}`, {
      'workspace id': workspace.id,
      'job opening id': jobOpening.id,
      'test id': test.id,
    });
    posthog?.capture(`hire_admin_app:page view/open ${pageName}`, {
      'workspace id': workspace.id,
      'job opening id': jobOpening.id,
      'test id': test.id,
    });
  }, [workspace, jobOpening, test, pageName, amplitude, posthog]);
}
