import { pick } from 'lodash';

import api from '@/api/api';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

import { IMe } from '../types';

export async function fetchMe(): Promise<IMe> {
  const response = await api.get(`/me`);

  return parseAPIData(response.data, {
    customizer(value, key) {
      if (
        value &&
        /subscription_paused_from$|subscription_paused_until$/.test(key)
      ) {
        return new Date(value);
      }
    },
  });
}

export async function updateMe(me: IMe): Promise<IMe> {
  const editableAttributes = pick(me, [
    'email',
    'name',
    'phone',
    'street',
    'city',
    'zipCode',
    'state',
    'country',
    'timezone',
    'jobRole',
  ]);
  const response = await api.put('/me', serialiseAPIData(editableAttributes));

  return parseAPIData(response.data);
}

export async function enableGmail(): Promise<string> {
  const response = await api.post('/me/gmail');

  return response.data.redirect_url;
}

export async function enableGmailCallback(params: {
  query: string;
}): Promise<string | null> {
  const response = await api.post(`/me/gmail-callback?${params.query}`);

  return response.data;
}

export async function deleteGmailIntegration(): Promise<void> {
  await api.delete('/me/gmail');
}

export async function deleteGmailData(): Promise<void> {
  await api.post('/me/gmail/delete-data');
}
