import React from 'react';

export const Illustration = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="52"
    height="86"
    viewBox="0 0 52 86"
    fill="none"
  >
    <g clipPath="url(#clip0_42_25118)">
      <ellipse
        cx="23.9982"
        cy="23.9981"
        rx="23.9982"
        ry="23.9981"
        transform="matrix(0.999829 0.0184863 -0.0186401 0.999826 3.8916 0.152527)"
        fill="#F5CBEF"
      />
      <ellipse
        cx="19.2542"
        cy="19.2542"
        rx="19.2542"
        ry="19.2542"
        transform="matrix(0.999829 0.018486 -0.0186398 0.999826 8.54688 4.98294)"
        fill="#EDA3E4"
      />
      <rect
        width="14.0573"
        height="14.0594"
        transform="matrix(0.99983 0.0184121 -0.018714 0.999825 20.5425 17.4303)"
        fill="white"
      />
      <path
        d="M27.1754 38.6329C29.0979 38.6683 30.915 38.3357 32.6266 37.635C34.3381 36.9343 35.8528 35.9492 37.1707 34.6798C38.4885 33.4104 39.5294 31.9361 40.2934 30.2568C41.0572 28.5774 41.4572 26.7723 41.4933 24.8414C41.5293 22.9196 41.197 21.1055 40.4966 19.3991C39.796 17.6928 38.811 16.1811 37.5416 14.8641C36.2721 13.547 34.7954 12.5047 33.1113 11.737C31.4272 10.9693 29.6194 10.5677 27.6879 10.5321C25.7654 10.4967 23.9505 10.8316 22.2433 11.5368C20.5362 12.2421 19.0236 13.2294 17.7058 14.4988C16.3879 15.7682 15.3471 17.2426 14.5832 18.9219C13.8194 20.6013 13.4195 22.4019 13.3835 24.3238C13.3474 26.2546 13.6795 28.0732 14.38 29.7796C15.0805 31.4858 16.0655 32.9975 17.3349 34.3145C18.6043 35.6316 20.0812 36.6717 21.7654 37.435C23.4496 38.1982 25.2529 38.5975 27.1754 38.6329ZM24.6659 30.1104C24.3298 30.3019 24.0058 30.3251 23.694 30.1802C23.3822 30.0352 23.2295 29.7875 23.2361 29.4373L23.4204 19.59C23.427 19.2398 23.5954 19.0003 23.9259 18.8716C24.2563 18.7429 24.5726 18.7712 24.8746 18.9565L32.9643 23.9162C33.2486 24.0922 33.3897 24.353 33.3877 24.6989C33.3858 25.0447 33.2348 25.3092 32.9348 25.4924L24.6659 30.1104Z"
        fill="#DD6FD1"
      />
      <path
        d="M31.5904 66.479C32.5863 62.4035 30.1502 60.7281 29.0242 60.1864C28.7053 60.0333 28.4851 59.7289 28.4317 59.371C28.1647 57.5864 27.1478 56.704 26.1524 56.2697C25.6968 56.071 25.2458 55.9662 24.8727 55.9116C24.7632 55.8956 24.6576 55.865 24.559 55.822C24.2075 55.6687 23.9392 55.3556 23.8562 54.9609C23.5225 53.3721 22.5506 52.5582 21.5974 52.1425C21.3423 52.0312 21.0885 51.9486 20.8489 51.8871C20.785 51.8706 20.7236 51.8494 20.665 51.8238C20.0553 51.5579 19.7582 50.8227 20.0348 50.171L23.0231 43.1317C23.7951 41.3128 22.8595 39.8276 21.5369 39.2508C20.2118 38.6729 18.4985 39.0066 17.725 40.829C16.4279 43.885 12.5317 53.0627 11.3577 55.8283C11.1592 56.2955 10.7087 56.5995 10.214 56.5974C3.99848 56.5704 6.1598 69.55 6.58029 71.7963C6.62139 72.0156 6.60461 72.2415 6.53204 72.4537L5.1518 76.4852C4.93738 77.1117 5.22274 77.8085 5.81324 78.066L5.81485 78.0667L18.5156 83.5711C19.0822 83.8174 19.7476 83.5864 20.0678 83.0397L22.3142 79.204C22.409 79.042 22.5402 78.9019 22.6933 78.7963C28.3858 74.8717 30.6671 70.2566 31.5904 66.479Z"
        fill="#CD9460"
      />
      <rect
        width="18.2887"
        height="7.78722"
        rx="1"
        transform="matrix(0.9201 0.391683 -0.383871 0.923387 6.29004 71.7364)"
        fill="#E57CD8"
      />
    </g>
    <defs>
      <clipPath id="clip0_42_25118">
        <rect width="52" height="86" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
