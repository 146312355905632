import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton, DSModal } from '@hundred5/design-system';
import { useMedia } from 'react-use';

import { useFreeTrial } from '@/features/billing';
import { Icon } from '@/features/common';
import { useNotifications } from '@/features/notifications';
import {
  useStartWorkspaceFreeTrialMutation,
  useWorkspace,
} from '@/features/workspace';
import { getCurrentSubscription, planLevelsType } from '@/hooks/planLimits';
import { useHistory } from '@/hooks/router';

import { useGenericPricingUpsellModalContext } from '../../hooks';

import { UPSELL_MODAL_COPIES } from './generic-pricing-upsell-modal.utils';

export const GenericPricingUpsellModal = () => {
  const history = useHistory();
  const { addNotification } = useNotifications();
  const { closePricingUpsell, isOpen, type, plan } =
    useGenericPricingUpsellModalContext();
  const { isTrialAvailable } = useFreeTrial();
  const workspace = useWorkspace();
  const isMobile = useMedia('(max-width: 768px)');
  const startFreeTrial = useStartWorkspaceFreeTrialMutation();

  if (!workspace) {
    return null;
  }

  const currentPlanToShow = plan
    ? plan
    : isTrialAvailable
      ? 'trial'
      : planLevelsType[getCurrentSubscription(workspace)] === 'free'
        ? 'starter'
        : 'premium';

  const handleStartTrial = async () => {
    await startFreeTrial.mutate(
      {
        workspaceId: workspace.id,
        subscribePlan: workspace.subscriptionPlan,
      },
      {
        onSuccess: () => addNotification({ type: 'free_trial_activation' }),
      }
    );
    closePricingUpsell();
  };

  return (
    <DSModal
      open={isOpen}
      onClose={closePricingUpsell}
      contentStyle={{
        height: '464px',
        width: isMobile ? '100%' : '800px',
      }}
      layer={5}
    >
      <DSModal.CloseButton onClick={closePricingUpsell} />
      <Container>
        <div>
          <ModalHeader>
            {UPSELL_MODAL_COPIES[type][currentPlanToShow]?.title}
          </ModalHeader>

          <ModalDescription>
            {UPSELL_MODAL_COPIES[type][currentPlanToShow]?.description}
          </ModalDescription>
          <ModalListTitle>
            {UPSELL_MODAL_COPIES[type][currentPlanToShow]?.listTitle}
          </ModalListTitle>

          <ModalListContainer>
            <>
              {UPSELL_MODAL_COPIES[type][currentPlanToShow]?.list.map(
                (item) => (
                  <ListItem key={item.text}>
                    {item.icon ? (
                      <Icon icon={item.icon} fixedWidth />
                    ) : (
                      item.customIcon?.()
                    )}
                    {item.text}
                  </ListItem>
                )
              )}
            </>
          </ModalListContainer>

          <ModalButtonContainer>
            {currentPlanToShow === 'trial' && (
              <ModalTrialButtonContainer>
                <DSButton variant="primary-purple" onClick={handleStartTrial}>
                  Start 14 day trial
                </DSButton>
                <NoCreditCard>
                  No credit card required
                  <Icon
                    data-rh={
                      'If you have already provided your billing details, you will be charged automatically after your trial ends.'
                    }
                    data-rh-at="bottom"
                    icon={regular('info-circle')}
                    height={12}
                    width={12}
                  />
                </NoCreditCard>
              </ModalTrialButtonContainer>
            )}

            {plan === 'premium' && type !== 'automation' && (
              <DSButton
                variant="secondary"
                onClick={() => {
                  window
                    .open(
                      'https://toggl.com/hire/demo?utm_source=product&utm_medium=button&utm_campaign=premium+upsell',
                      '_blank'
                    )
                    ?.focus();
                }}
              >
                Book a demo
              </DSButton>
            )}
            {(currentPlanToShow === 'starter' ||
              currentPlanToShow === 'premium') && (
              <DSButton
                variant="primary-purple"
                onClick={() => {
                  history.push(`/admin/${workspace?.id}/settings/billing`);
                  closePricingUpsell();
                }}
              >
                Upgrade
              </DSButton>
            )}
          </ModalButtonContainer>
        </div>
        {UPSELL_MODAL_COPIES[type].illustration()}
      </Container>
    </DSModal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ModalHeader = styled.div`
  color: ${({ theme }) => theme.colors.purple[100]};
  font-family: GT Haptik;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  margin-bottom: 8px;
`;

const ModalDescription = styled.div`
  color: ${({ theme }) => theme.colors.purple[60]};
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  max-width: 431px;
  margin-bottom: 16px;
`;

const ModalListTitle = styled.div`
  color: ${({ theme }) => theme.colors.purple[100]};
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
`;

const ModalListContainer = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: ${({ theme }) => theme.colors.purple[100]};
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
  margin-bottom: 36px;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const ModalTrialButtonContainer = styled.div`
  width: 159px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ListItem = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const NoCreditCard = styled.span`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.purple[60]};
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  margin-top: 8px;
  gap: 4px;
`;
