import styled from '@emotion/styled';

import { pluralize } from '@/features/common';

import { UnitInputWrapper } from './unit-input-wrapper';

export const TimeInputWrapper = styled(UnitInputWrapper)<{
  duration: number;
  timeUnit: string;
}>`
  ::after {
    content: ${(props) => `"${pluralize(+props.duration, props.timeUnit)}"`};
  }

  input {
    padding-right: 64px;
  }
`;
