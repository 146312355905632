import { useCallback } from 'react';
import { usePostHog } from 'posthog-js/react';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { TId } from '@/features/common';
import { IQuestion, useRegenerateQuestionMutation } from '@/features/questions';
import { isQuestionLimitExceededError } from '@/features/test/utils/question-limit-exceeded';
import { useApiError } from '@/hooks/api';
import { useUpsellModal } from '@/hooks/upsellModal';

export const useRegenerateQuestion = (jobOpeningId: TId, testId: TId) => {
  const regenerateQuestionMutation = useRegenerateQuestionMutation({
    testId,
  });
  const { openUpsellModal } = useUpsellModal();
  const handleApiError = useApiError();
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();
  const regenerateQuestion = useCallback(
    async (
      { skill, ...question }: IQuestion,
      onSuccess?: (question: IQuestion) => void
    ) => {
      await regenerateQuestionMutation.mutateAsync(
        {
          question: {
            ...question,
            skillId: skill?.id ?? '',
          },
        },
        {
          onSuccess,
          onError: (error) => {
            if (isQuestionLimitExceededError(error)) {
              openUpsellModal('question_generation_limit', {
                meta: {
                  skillName: skill?.name,
                },
              });
            } else {
              handleApiError(error);
            }
          },
        }
      );
      amplitude?.logEvent('question creation/regenerate question', {
        'job opening id': jobOpeningId,
        'test id': testId,
        'question id': question.id,
        skill: skill?.name,
      });
      posthog?.capture('hire_admin_app:question creation/regenerate question', {
        'job opening id': jobOpeningId,
        'test id': testId,
        'question id': question.id,
        skill: skill?.name,
      });
    },
    [
      amplitude,
      handleApiError,
      jobOpeningId,
      openUpsellModal,
      regenerateQuestionMutation,
      testId,
      posthog,
    ]
  );

  return {
    regenerateQuestion,
    isRegenerating: regenerateQuestionMutation.isLoading,
  };
};
