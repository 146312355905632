import React from 'react';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';

import { PageHeader, TId } from '@/features/common';

export function TestLibraryHeader() {
  const isMobile = useMedia('(max-width: 768px)');
  const { jobOpeningId } = useParams<{
    jobOpeningId?: TId;
  }>();

  const title = !!jobOpeningId ? 'Add a test' : 'Test Library';

  return (
    <PageHeader
      title={title}
      backButton={
        !!jobOpeningId
          ? { to: `/openings/${jobOpeningId}`, tooltip: 'Go back to pipeline' }
          : undefined
      }
      sticky={isMobile}
    />
  );
}
