import { usePostHog } from 'posthog-js/react';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { trackIntercomEvent } from '@/features/intercom';
import { INotification } from '@/features/notifications';

export function useLogNotification() {
  const posthog = usePostHog();
  const amplitude = getAmplitudeClient();

  function logNewNotification(newNotification: INotification) {
    amplitude?.logEvent('show notification', {
      kind: newNotification.content.kind,
      type: newNotification.content.type,
      meta: newNotification.content.meta,
    });

    posthog?.capture('hire_admin_app:show notification', {
      kind: newNotification.content.kind,
      type: newNotification.content.type,
      meta: newNotification.content.meta,
    });

    if (newNotification.content.kind === 'failure') {
      trackIntercomEvent('failure_notification_received', {
        type: newNotification.content.type,
        meta: newNotification.content.meta,
      });
    }
  }

  return { logNewNotification };
}
