import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  DSButton,
  DSCombobox,
  DSField,
  DSInput,
  DSSelect,
  DSSwitch,
} from '@hundred5/design-system';
import { usePostHog } from 'posthog-js/react';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { Icon, TId } from '@/features/common';
import {
  ATTACHMENTS_OPTIONS,
  IQuestion,
  useCreateLibraryQuestionMutation,
} from '@/features/questions';
import { ICreateLibraryQuestionParams } from '@/features/questions/api';
import { ISkill } from '@/features/skills';
import { TTestType, useTestPage } from '@/features/test';
import { QuestionSettings } from '@/features/test/components/test-editor-question-preview/ui';
import { useSkills } from '@/features/test/hooks/use-skills';
import { LIBRARY_QUESTION_TYPE_OPTIONS } from '@/features/test/utils/library-question-options';
import { isQuestionLimitExceededError } from '@/features/test/utils/question-limit-exceeded';
import { skillHasQuestionsForTestType } from '@/features/test/utils/skills';
import { useApiError } from '@/hooks/api';
import { useHistory } from '@/hooks/router';
import { useUpsellModal } from '@/hooks/upsellModal';

import { Container, PreviewHeader } from '../ui';

import { CreateQuestionInfoIcon } from './ui/create-question-info-icon';

const QUESTION_DIFFICULTY_OPTIONS = [{ id: 'standard', label: 'Standard' }];

function noop() {}

function getDefaultQuestionType(
  skills: ISkill[],
  selectedSkillId: TId,
  testType: TTestType,
  isVideoQuestion: boolean,
  isHomeworkQuestion: boolean
) {
  if (isVideoQuestion) return 'video-question';
  if (isHomeworkQuestion) return 'placeholder-open-ended';

  // if skill was selected it exists
  const skill = skills.find((skill) => skill.id === selectedSkillId)!;

  if (skill.questions[testType]['placeholderCloseEnded']?.standard) {
    return 'placeholder-close-ended';
  } else if (skill.questions[testType]['codeInput']?.standard) {
    return 'code-input';
  }

  return 'free-text';
}

export const CreateLibraryQuestion = ({
  index,
  onCancel,
}: {
  index: number;
  onCancel: () => void;
}) => {
  const { workspace, jobOpening, test } = useTestPage();
  const history = useHistory();
  const { openUpsellModal } = useUpsellModal();
  const handleApiError = useApiError();
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();
  const isVideoQuestion = test.type === 'video';
  const isHomeworkQuestion = test.type === 'homework';

  const { skills } = useSkills(test.type);

  const skillsOptions = useMemo(
    () =>
      skills.map((s) => ({
        ...s,
        disabled: !skillHasQuestionsForTestType(s, test.type),
      })),
    [skills, test.type]
  );

  const createQuestionMutation = useCreateLibraryQuestionMutation({
    testId: test.id,
    onSuccess: (question: IQuestion) => {
      amplitude?.logEvent('question creation/create library question', {
        'job opening id': jobOpening.id,
        'test id': test.id,
        skill: skills.find((skill) => skill.id === question.skill?.id)?.name,
      });
      posthog?.capture(
        'hire_admin_app:question creation/create library question',
        {
          'job opening id': jobOpening.id,
          'test id': test.id,
          skill: skills.find((skill) => skill.id === question.skill?.id)?.name,
        }
      );
      history.push(
        `/admin/${workspace.id}/openings/${jobOpening.id}/test/${test.id}/questions/${question.id}${history.location.search}`
      );
    },
    onError: (error, variables: ICreateLibraryQuestionParams) => {
      if (isQuestionLimitExceededError(error)) {
        openUpsellModal('question_generation_limit', {
          meta: {
            skillName: skills.find(
              (skill) => skill.id === variables.question.skillId
            )?.name,
          },
        });
      } else {
        handleApiError(error);
      }
    },
  });

  const handleSkillSelection = async (skillId: string) => {
    if (!skillId) return;

    await createQuestionMutation.mutateAsync({
      testId: test.id,
      question: {
        skillId: skillId,
        questionType: getDefaultQuestionType(
          skills,
          skillId,
          test.type,
          isVideoQuestion,
          isHomeworkQuestion
        ),
        difficulty: 'standard',
        isRandom: false,
        points: 1,
        orderWeight: index,
      },
    });
  };

  return (
    <Container>
      <PreviewHeader.Wrapper>
        <PreviewHeader.QuestionName>
          Question {index + 1}
        </PreviewHeader.QuestionName>
        <PreviewHeader.Actions>
          <DSButton variant="secondary" size="small" onClick={() => onCancel()}>
            <Icon icon={regular('trash')} />
            <span>Remove</span>
          </DSButton>
        </PreviewHeader.Actions>
      </PreviewHeader.Wrapper>
      <QuestionSettings>
        <DSField for="skill" label="Skill">
          <DSCombobox
            items={skillsOptions}
            filterBy="name"
            labelField="name"
            groupBy="type"
            onChange={(skill: ISkill) => handleSkillSelection(skill.id)}
            placeholder="Search for a skill"
            noResultsMessage="No skills found"
            maxDropdownHeight="290px"
            onSearchChange={(query: string | undefined) => {
              amplitude?.logEvent('question creation/search for skill', {
                'job opening id': jobOpening.id,
                'test id': test.id,
                query,
              });
              posthog?.capture(
                'hire_admin_app:question creation/search for skill',
                {
                  'job opening id': jobOpening.id,
                  'test id': test.id,
                  query,
                }
              );
            }}
          />
        </DSField>
        {!isVideoQuestion && !isHomeworkQuestion ? (
          <DSField for="type" label="Question type">
            <DSSelect
              items={LIBRARY_QUESTION_TYPE_OPTIONS}
              groupBy="group"
              onChange={noop}
              disabled
              placeholder="Select a question type"
            />
          </DSField>
        ) : null}
        {!isVideoQuestion && !isHomeworkQuestion ? (
          <DSField for="difficulty" label="Difficulty">
            <DSSelect
              items={QUESTION_DIFFICULTY_OPTIONS}
              onChange={noop}
              selectedItemId="standard"
              disabled
            />
          </DSField>
        ) : null}
        {!isVideoQuestion && !isHomeworkQuestion ? (
          <DSField for="isRandom" label="Randomize">
            <DSSwitch checked={true} disabled />
          </DSField>
        ) : null}
        {isHomeworkQuestion ? (
          <AttachmentsField
            for="attachments"
            label={
              <>
                Attachments
                <Icon
                  style={{ marginLeft: '4px' }}
                  icon={regular('circle-info')}
                  color="purple-60"
                  fontSize="12px"
                  data-rh="Accepted file types: doc, docx, xls, xlsx, odt, pdf, txt, jpg, jpeg, png"
                  data-rh-position="top"
                />
              </>
            }
          >
            <DSSelect
              items={ATTACHMENTS_OPTIONS}
              onChange={noop}
              selectedItemId="optional"
              disabled
            />
          </AttachmentsField>
        ) : null}
        <DSField for="points" label="Points">
          <PointsInput type="number" value={1} disabled />
        </DSField>
      </QuestionSettings>
      <CreateQuestionInfo>
        <CreateQuestionInfoIcon />
        <CreateQuestionInfoTitle>
          Pick a skill to see a preview
        </CreateQuestionInfoTitle>
        <CreateQuestionInfoDescription>
          Choose from 150+ skills
        </CreateQuestionInfoDescription>
      </CreateQuestionInfo>
    </Container>
  );
};

const CreateQuestionInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${(props) => props.theme.colors.purple[60]};
  height: 300px;
`;

const CreateQuestionInfoTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
`;

const CreateQuestionInfoDescription = styled.p`
  font-size: 12px;
  font-weight: 400;
`;

const AttachmentsField = styled(DSField)`
  grid-column: -4 / span 2;
`;

const PointsInput = styled(DSInput)`
  padding-right: clamp(2px, 8%, 12px);
`;
