import styled from '@emotion/styled';

export const Badge = styled.span<{ active: boolean; persistent?: boolean }>`
  display: ${({ persistent }) =>
    persistent ? 'flex' : 'none'}; // none: controlled by parent
  align-items: center;
  justify-content: center;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.white : theme.colors.purple[90]};
  border-radius: 8px;
  color: ${({ active, theme }) =>
    active ? theme.colors.purple[100] : theme.colors.white};
  font-size: 10px;
  font-weight: 600;
  padding: 0 8px;
  height: 16px;
`;
