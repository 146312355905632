import React from 'react';
import styled from '@emotion/styled';
import { DSIconArrowLeft, DSIconArrowRight } from '@hundred5/design-system';
import { format } from 'date-fns/format';
import {
  DayPicker as ReactDayPicker,
  DayPickerProps,
  Styles,
} from 'react-day-picker';
import 'react-day-picker/style.css';

export function DayPicker(props: DayPickerProps) {
  const customStyles: Partial<Styles> = {
    root: {
      margin: '12px',
    },
    month_caption: {
      justifyContent: 'center',
      fontWeight: 500,
      marginBottom: '8px',
    },
    week: {
      borderTop: '1px solid #eae7eb',
    },
    day: {
      borderRadius: '8px',
      width: '36px',
      height: '36px',
    },
    day_button: {
      width: '36px',
      height: '36px',
      fontSize: '14px',
      padding: 0,
      backgroundClip: 'content-box',
      borderRadius: '8px',
    },
    nav: {
      position: 'absolute',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      left: 0,
      right: 0,
    },
  };

  return (
    <Wrapper>
      <ReactDayPicker
        mode={props.mode}
        weekStartsOn={1}
        components={{
          Chevron: (props) => {
            if (props.orientation === 'left') {
              return <ChevronLeftIcon {...props} />;
            }
            return <ChevronRightIcon {...props} />;
          },
        }}
        styles={customStyles}
        classNames={{
          selected: 'rdp-selected',
        }}
        formatters={{
          formatCaption: (month) => format(month, 'LLLL'),
          formatWeekdayName: (day) => format(day, 'EEE'),
        }}
        {...props}
      />
    </Wrapper>
  );
}

const ChevronLeftIcon = (props) => {
  return (
    <NavButton prev type="button" aria-label="Previous Month" {...props}>
      <DSIconArrowLeft />
    </NavButton>
  );
};

const ChevronRightIcon = (props) => {
  return (
    <NavButton type="button" aria-label="Next Month" {...props}>
      <DSIconArrowRight />
    </NavButton>
  );
};

const Wrapper = styled.div`
  .rdp-selected .rdp-day_button {
    border: 2px solid white;
    background: ${(props) => props.theme.colors.orange[100]};
    color: ${(props) => props.theme.colors.white};
  }

  .rdp-day:not(.rdp-disabled):not(.rdp-selected):not(.rdp-range_start):not(
      .rdp-range_middle
    ):not(.rdp-range_end)
    .rdp-day_button:hover {
    background-color: ${(props) => props.theme.hover.onWhite};
  }
`;

const NavButton = styled.button<{ prev?: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  cursor: pointer;
  border-radius: ${(props) => (props.prev ? '8px 0 0 8px' : '0 8px 8px 0')};
  height: 36px;
  width: 36px;
  outline: none;
  color: ${(props) => props.theme.colors.purple[100]};
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.purple[40]};
  transition:
    background 0.1s,
    transform 0.1s;

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }

  &:active {
    transform: scale(0.96);
  }

  &:hover {
    background: ${(props) => props.theme.hover.onWhite};
  }

  svg {
    height: 10px;
    width: 10px;
  }
`;
