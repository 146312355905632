import { TQuestionType } from '@/features/questions';

export const CUSTOM_QUESTION_TYPE_OPTIONS: {
  id: TQuestionType | string;
  label: string;
  labelText: string;
  group: string;
  disabled?: boolean;
}[] = [
  {
    id: 'single-choice',
    label: 'Single Choice',
    labelText: 'Single Choice',
    group: 'Auto evaluation',
  },
  {
    id: 'multiple-choice',
    label: 'Multiple Choice',
    labelText: 'Multiple Choice',
    group: 'Auto evaluation',
  },
  {
    id: 'picture-question',
    label: 'Picture Choice',
    labelText: 'Picture Choice',
    group: 'Auto evaluation',
  },
  {
    id: 'numeric-input',
    label: 'Numeric Input',
    labelText: 'Numeric Input',
    group: 'Auto evaluation',
  },
  {
    id: 'free-text',
    label: 'Free text',
    labelText: 'Free text',
    group: 'Requires manual evaluation',
  },
  {
    id: 'code-input',
    label: 'Coding',
    labelText: 'Coding',
    group: 'Requires manual evaluation',
  },
];
