import React from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '../icon';

interface ZapProps {
  active: boolean;
  animation?: boolean;
  tooltipPosition: boolean; // category.isSystem
  onClick: () => void;
}

export function Zap({ active, animation, tooltipPosition, onClick }: ZapProps) {
  return (
    <Button
      data-rh={!active ? 'Add and view automation' : null}
      data-rh-at={tooltipPosition ? 'right' : 'top'}
      onClick={onClick}
    >
      {animation && active && (
        <AnimationSvg icon={solid('bolt')} color="orange-60"></AnimationSvg>
      )}
      <StyledIcon
        icon={active ? solid('bolt') : regular('bolt')}
        color={active ? 'orange-100' : 'purple-60'}
      ></StyledIcon>
    </Button>
  );
}

const ping = keyframes`
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
`;

const Button = styled.button`
  position: relative;
  border: 0;
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.purple[5]};
  }
`;

const AnimationSvg = styled(Icon)`
  animation: ${ping} 1250ms infinite;
  position: absolute;
  top: 6px;
  left: 6px;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 6px;
  left: 6px;
`;
