import { RefObject, useEffect } from 'react';

const EVENTS = [`click`, `touchstart`];

export function useClickOutside(
  ref: RefObject<HTMLElement>,
  handler: (event: Event) => void
) {
  useEffect(() => {
    if (!ref.current || !handler) {
      return;
    }
    const listener = (event: Event) => {
      if (ref.current?.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    // We need to use capture phase to prevent the event from being stopped by other handlers: https://legacy.reactjs.org/blog/2020/08/10/react-v17-rc.html#fixing-potential-issues
    EVENTS.forEach((event) => {
      document.addEventListener(event, listener, { capture: true });
    });

    return () => {
      EVENTS.forEach((event) => {
        document.removeEventListener(event, listener);
      });
    };
  }, [handler, ref]);
}
