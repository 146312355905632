import styled from '@emotion/styled';

import { UnitInputWrapper } from './unit-input-wrapper';

export const PercentageInputWrapper = styled(UnitInputWrapper)`
  ::after {
    content: '%';
  }

  input {
    padding-right: 30px;
  }
`;
