import React from 'react';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useHistory } from 'react-router';
import { useMedia } from 'react-use';

import { useInboxTotalUnreadQuery } from '@/features/inbox';
import { useCreateJobOpeningMutation } from '@/features/job-opening';
import { NavigationListItemProps } from '@/features/navigation/components/navigation-list/navigation-list-item';
import { useWorkspacePermission } from '@/features/permissions';
import { useShareModal } from '@/features/share';
import { useGenericPricingUpsellModalContext } from '@/features/upsell';
import { useJobOpeningsLimit } from '@/hooks/planLimits';
import { useWorkspaceId } from '@/hooks/router';

import { NavigationList } from '../navigation-list';

export function Shortcuts() {
  const history = useHistory();
  const workspaceId = useWorkspaceId();
  const isMobile = useMedia('(max-width: 768px)');

  const { openPricingUpsell } = useGenericPricingUpsellModalContext();
  const { openShareModal } = useShareModal();

  const { data: inboxTotalUnread } = useInboxTotalUnreadQuery();
  const { mutate: createJobOpeningMutation, isLoading: creating } =
    useCreateJobOpeningMutation();

  const isBelowJobOpeningsLimit = useJobOpeningsLimit();
  const canAddCandidate = useWorkspacePermission()('candidates', 'update');
  const canCreateJobOpening = useWorkspacePermission()(
    'job openings',
    'create'
  );

  const onAddNewJob = () => {
    !isBelowJobOpeningsLimit
      ? openPricingUpsell('jobs')
      : createJobOpeningMutation(
          {},
          {
            onSuccess: (jobOpening) => {
              history.push(
                `/admin/${workspaceId}/openings/${jobOpening.id}/pipeline`
              );
            },
          }
        );
  };

  const shortcuts: NavigationListItemProps[] = [
    {
      label: 'Inbox',
      url: `/admin/${workspaceId}/inbox`,
      visible: !isMobile,
      badge: {
        value: inboxTotalUnread || 0,
        persistent: true,
      },
      active: history.location.pathname.includes('inbox'),
      icon: {
        default: regular('inbox'),
        active: solid('inbox'),
      },
    },
    {
      label: 'Analytics',
      url: `/admin/${workspaceId}/analytics`,
      visible: !isMobile,
      badge: {
        value: 'NEW',
        persistent: true,
      },
      active: !!history.location.pathname.match('/admin/[0-9]+/analytics'),
      icon: {
        default: regular('chart-simple'),
        active: solid('chart-simple'),
      },
    },
    {
      label: 'Test Library',
      url: `/admin/${workspaceId}/test-library`,
      active: history.location.pathname.includes('test-library'),
      icon: {
        default: regular('book-open'),
        active: solid('book-open'),
      },
    },
    {
      label: 'Candidates',
      url: `/admin/${workspaceId}/candidates?page=1&items_per_page=10&sort_order=date&sort_direction=desc`,
      active: history.location.pathname.includes('candidates'),
      disabled: isMobile,
      icon: {
        default: regular('users'),
        active: solid('users'),
      },
      buttonProps: {
        visible: !!canAddCandidate,
        onClick: () => openShareModal(''),
        tooltip: 'Add candidate',
      },
    },
    {
      label: 'Jobs',
      url: `/admin/${workspaceId}/openings`,
      active: !!history.location.pathname.match(/openings$/),
      icon: {
        default: regular('briefcase'),
        active: solid('briefcase'),
      },
      buttonProps: {
        visible: !isMobile && !!canCreateJobOpening,
        onClick: onAddNewJob,
        disabled: !canCreateJobOpening || creating,
        tooltip: !canCreateJobOpening
          ? 'Access restricted. Contact workspace admin to change your user rights.'
          : 'Add new job',
      },
    },
  ];

  return (
    <NavigationList>
      {shortcuts.map((item) => (
        <NavigationList.Item key={item.label} {...item} />
      ))}
    </NavigationList>
  );
}
