import store from 'store';

import { userHasConsentedToPreferences } from '@/features/gdpr/utils/permissions';

const ACTIVE_DASHBOARD_TAB_KEY = `toggl_hire_active_dashboard_tab`;

export const getActiveDashboardTab = (): 'openings' | 'candidates' | 'inbox' =>
  store.get(ACTIVE_DASHBOARD_TAB_KEY) || 'openings';

export const setActiveDashboardTab = (
  tab: 'openings' | 'candidates' | 'inbox'
) => {
  if (userHasConsentedToPreferences()) {
    store.set(ACTIVE_DASHBOARD_TAB_KEY, tab);
  }
};
