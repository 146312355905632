import { object, string } from 'yup';

import { IWorkspace } from '@/features/workspace';

import { TWorkspaceAttributes } from './workspace-settings-general.types';

export const schema = object().shape({
  name: string().required("This can't be blank!"),
});

export const createWorkspaceAttributes = (
  workspace: IWorkspace | undefined
): TWorkspaceAttributes => ({
  name: workspace ? workspace.name : '',
  logoUrl: workspace ? workspace.logoUrl : '',
  company: workspace ? workspace.company : '',
});
