import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { checkClientError } from '@/errors';
import { CANDIDATE_EMAILS_QUERY_KEY } from '@/features/candidate';
import { useNotifications } from '@/features/notifications';
import { useApiError } from '@/hooks/api';

import {
  deleteGmailData,
  deleteGmailIntegration,
  enableGmail,
  enableGmailCallback,
  fetchMe,
  updateMe,
} from '../api';
import { isUserInSupportMode } from '../utils/me';

export const ME_QUERY_KEY = ['me'];

export const useMeQuery = (options?: { enabled?: boolean }) => {
  return useQuery(ME_QUERY_KEY, fetchMe, {
    enabled: options?.enabled ?? true,
    onSuccess: async (me) => {
      // update timezone if none is set
      if (me.timezone === null && !isUserInSupportMode(me)) {
        const region = new Intl.DateTimeFormat();
        const options = region.resolvedOptions();

        if (options.timeZone) {
          await updateMe({ ...me, timezone: options.timeZone });
        }
      }
    },
  });
};

export const useMeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(updateMe, {
    onSuccess: () => queryClient.invalidateQueries(ME_QUERY_KEY),
  });
};

export const useEnableGmailMutation = (options?: UseMutationOptions) => {
  const { addNotification } = useNotifications();
  const queryClient = useQueryClient();

  return useMutation(() => enableGmail(), {
    mutationKey: ME_QUERY_KEY,
    onSuccess: () => {
      addNotification({ type: 'saved' });
      queryClient.invalidateQueries(ME_QUERY_KEY);
    },
    ...(options as any),
  });
};

export const useEnableGmailCallbackMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotifications();
  const handleApiError = useApiError();

  return useMutation((query: string) => enableGmailCallback({ query }), {
    mutationKey: ME_QUERY_KEY,
    onSuccess: () => {
      addNotification({ type: 'saved' });
      queryClient.invalidateQueries(ME_QUERY_KEY);
    },
    onError: (error) => {
      if (checkClientError(error, 'GmailMailboxAlreadyConnected')) {
        addNotification({ type: 'gmail_mailbox_already_connected' });
      } else {
        handleApiError(error);
      }
    },
    ...(options as any),
  });
};

export const useDeleteGmailIntegrationMutation = (
  params?: {
    onSuccess?: () => void;
  },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotifications();

  return useMutation(deleteGmailIntegration, {
    onSuccess: () => {
      queryClient.invalidateQueries(ME_QUERY_KEY);
      addNotification({ type: 'saved' });
      params?.onSuccess?.();
    },
    ...(options as any),
  });
};

export const useDeleteGmailDataMutation = (
  params?: {
    onSuccess?: () => void;
  },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotifications();

  return useMutation(deleteGmailData, {
    onSuccess: () => {
      queryClient.invalidateQueries(ME_QUERY_KEY);
      queryClient.invalidateQueries(CANDIDATE_EMAILS_QUERY_KEY);

      addNotification({ type: 'gmail_user_data_deleted' });
      params?.onSuccess?.();
    },
    ...(options as any),
  });
};
