import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { object, string } from 'yup';

import { getAmplitudeClient } from '@/features/amplitude';
import {
  FormikInputField,
  FormikSelectField,
  TogglLogo,
} from '@/features/common';
import { useMeMutation, useMeQuery } from '@/features/me';
import { useUpdateTogglAccountsMeMutation } from '@/features/toggl-accounts';
import {
  useUpdateWorkspaceMutation,
  useWorkspaceQuery,
} from '@/features/workspace';
import { useWorkspaceId } from '@/hooks/router';

import { JOB_ROLES } from './onboarding-form.utils';

const validationSchema = object().shape({
  role: string().required('Please select your role'),
  company: string().required('Please enter company name'),
});

export function OnboardingForm() {
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();
  const workspaceId = useWorkspaceId();

  const { data: me } = useMeQuery();
  const { data: workspace } = useWorkspaceQuery({ workspaceId });

  const meMutation = useMeMutation();
  const togglMeMutation = useUpdateTogglAccountsMeMutation();
  const workspaceMutation = useUpdateWorkspaceMutation({});

  const handleSubmit = async (values: { role: string; company: string }) => {
    if (!me || !workspace) {
      return;
    }

    try {
      const promises: Promise<any>[] = [];

      promises.push(
        meMutation.mutateAsync({
          ...me,
          jobRole: values.role,
        })
      );

      promises.push(
        workspaceMutation.mutateAsync({
          workspaceId: workspace.id,
          workspace: {
            ...workspace,
            name: values.company,
            company: values.company,
          },
        })
      );

      await Promise.all(promises);

      amplitude?.logEvent('onboarding/form submit', {
        'workspace id': workspaceId,
      });
      posthog?.capture('hire_admin_app:onboarding/form submit', {
        'workspace id': workspaceId,
      });
    } catch {}
  };

  useEffect(() => {
    amplitude?.logEvent('onboarding/form view', {
      'workspace id': workspaceId,
    });
    posthog?.capture('hire_admin_app:onboarding/form view', {
      'workspace id': workspaceId,
    });
  }, [amplitude, workspaceId, posthog]);

  return (
    <Container>
      <TogglLogo height={42} width={186} />

      <Header>
        <h1>Set up your workspace</h1>
        <p>First things first, tell us a bit about yourself</p>
      </Header>

      <Formik
        initialValues={{
          role: me?.jobRole || '',
          company: workspace?.company || '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <FormContent>
          <Fields>
            <FormikSelectField
              name="role"
              label="Your role"
              placeholder="Select your role"
              items={JOB_ROLES}
              required
            />
            <FormikInputField
              name="company"
              label="Company name"
              placeholder="My company"
              required
            />
          </Fields>

          <DSButton
            type="submit"
            variant="primary-purple"
            disabled={
              meMutation.isLoading ||
              togglMeMutation.isLoading ||
              workspaceMutation.isLoading
            }
          >
            Continue
          </DSButton>
        </FormContent>
      </Formik>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 32px 30px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  width: 346px;
  max-width: 100%;
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  color: ${(props) => props.theme.typography.colorPrimary};

  h1 {
    margin: 0;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: 'GT Haptik';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
  }
`;

const FormContent = styled(Form)`
  display: grid;
  gap: 24px;
  width: 100%;

  button {
    width: 100%;
  }
`;

const Fields = styled.div`
  display: grid;
  gap: 20px;

  .dropdown {
    max-height: max-content;
  }
`;
