import { useEffect, useMemo } from 'react';
import smartlookClient from 'smartlook-client';

import { userHasConsentedToStatistics } from '@/features/gdpr/utils/permissions';
import { useMeQuery } from '@/features/me';
import { useWorkspaceUsersQuery } from '@/features/workspace';
import { useWorkspaceIdOrNull } from '@/hooks/router';

export function useSmartlook() {
  const workspaceId = useWorkspaceIdOrNull();
  const meQuery = useMeQuery();
  const workspaceUsersQuery = useWorkspaceUsersQuery();

  const workspaceUser = useMemo(() => {
    if (!meQuery.isSuccess || !workspaceUsersQuery.isSuccess) {
      return null;
    }

    return workspaceUsersQuery.data.find((user) => user.id === meQuery.data.id);
  }, [
    meQuery.isSuccess,
    meQuery.data?.id,
    workspaceUsersQuery.isSuccess,
    workspaceUsersQuery.data,
  ]);

  const workspace = useMemo(() => {
    if (!meQuery.isSuccess) {
      return null;
    }

    return meQuery.data.workspaces?.find(
      (workspace) => workspace.id === workspaceId
    );
  }, [meQuery.isSuccess, meQuery.data, workspaceId]);

  useEffect(() => {
    if (!smartlookClient.initialized() || !userHasConsentedToStatistics())
      return;

    if (meQuery.isSuccess) {
      const idProps: { [index: string]: string | number | boolean } = {
        id: meQuery.data.id,
        user_role: workspaceUser?.role ?? '',
        workspace_id: workspace?.id ?? '',
        subscription_plan: workspace?.subscriptionPlan ?? '',
      };

      smartlookClient.identify(meQuery.data.id, idProps);
      smartlookClient.record({ forms: true, numbers: true, api: true });
    }
  }, [workspaceUser, workspace, meQuery.isSuccess, meQuery.data]);
}

export const useSmartlookPause = () => {
  useEffect(() => {
    if (!smartlookClient.initialized() || !userHasConsentedToStatistics())
      return;

    smartlookClient.pause();

    return () => {
      smartlookClient.resume();
    };
  }, []);
};
