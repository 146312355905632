import React from 'react';
import styled from '@emotion/styled';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';

import { Badge, Button, Label, Link } from './ui';

export interface NavigationListItemProps {
  label: string;
  url: string;
  visible?: boolean;
  disabled?: boolean;
  active?: boolean;
  icon?: {
    default: IconDefinition;
    active?: IconDefinition;
  };
  buttonProps?: {
    visible?: boolean;
    disabled?: boolean;
    onClick: () => void;
    tooltip: string;
  };
  badge?: {
    value: number | string;
    persistent?: boolean;
  };
}

export function NavigationListItem({
  label,
  url,
  visible = true,
  disabled = false,
  active = false,
  icon,
  buttonProps,
  badge,
}: NavigationListItemProps) {
  return (
    visible && (
      <Item>
        <Link
          to={url}
          disabled={disabled}
          active={active}
          persistentBadge={!!badge?.persistent}
        >
          {icon && (
            <Icon
              icon={active ? icon.active || icon.default : icon.default}
              color="white"
              fixedWidth
            />
          )}
          <Label>{label}</Label>
          {badge && (
            <Badge active={active} persistent={badge.persistent}>
              {badge.value}
            </Badge>
          )}
        </Link>

        {buttonProps?.visible && (
          <Button
            type="button"
            active={active}
            onClick={buttonProps.onClick}
            disabled={buttonProps.disabled}
            data-navigation-tooltip={buttonProps.tooltip}
            data-navigation-tooltip-at="right"
          >
            <Icon
              icon={solid('plus')}
              color={active ? 'white' : 'purple-60'}
              fontSize="16px"
            />
          </Button>
        )}
      </Item>
    )
  );
}

const Item = styled.li`
  position: relative;

  &:hover {
    ${Button} {
      display: flex;
    }
  }

  @media all and (max-width: 768px) {
    ${Button} {
      display: flex;
    }
  }
`;
