import { TPermissionsAction, TResource, TWorkspaceRole } from '../types';

import { permissionsFactory } from './permissions-factory';

export const permissions = permissionsFactory<
  TWorkspaceRole,
  TResource,
  TPermissionsAction
>();

// workspace level roles
permissions.role('viewer');
permissions.role('manager', 'viewer');
permissions.role('admin', 'manager');
permissions.role('owner', 'admin');
permissions.role('hundred5-support');

// job opening level roles
permissions.role('job_opening_viewer');
permissions.role('job_opening_notes_only', 'job_opening_viewer');
permissions.role('job_opening_editor', 'job_opening_notes_only');

// RESOURCE: job openings
permissions.resource('job openings');
permissions.allow('manager', 'job openings', ['read']);
permissions.allow('admin', 'job openings', [
  'read',
  'create',
  'update',
  'close',
  'delete',
]);
permissions.allow('hundred5-support', 'job openings', [
  'read',
  'create',
  'update',
  'close',
  'delete',
]);

// RESOURCE: job opening
permissions.resource('job opening');
permissions.allow('manager', 'job opening', ['read']);
permissions.allow('admin', 'job opening', [
  'create',
  'update',
  'close',
  'delete',
]);
permissions.allow('hundred5-support', 'job opening', [
  'read',
  'create',
  'update',
  'close',
  'delete',
]);
permissions.allow('job_opening_viewer', 'job opening', ['read']);
permissions.allow('job_opening_editor', 'job opening', [
  'update',
  'close',
  'delete',
]);

// RESOURCE: pipeline
permissions.resource('pipeline');
permissions.allow('admin', 'pipeline', ['update']);
permissions.allow('hundred5-support', 'pipeline', ['update']);
permissions.allow('job_opening_editor', 'pipeline', ['update']);

// RESOURCE: test
permissions.resource('test');
permissions.allow('manager', 'test', ['read']);
permissions.allow('admin', 'test', ['create', 'update', 'delete']);
permissions.allow('hundred5-support', 'test', [
  'read',
  'create',
  'update',
  'delete',
]);
permissions.allow('job_opening_viewer', 'test', ['read']);
permissions.allow('job_opening_editor', 'test', ['create', 'update', 'delete']);

// RESOURCE: candidates
permissions.resource('candidates');
permissions.allow('viewer', 'candidates', ['read']);
permissions.allow('admin', 'candidates', ['read', 'update', 'delete']);
permissions.allow('hundred5-support', 'candidates', [
  'read',
  'update',
  'delete',
]);

// RESOURCE: candidate
permissions.resource('candidate');
permissions.allow('viewer', 'candidate', ['read']);
permissions.allow('admin', 'candidate', ['update', 'delete']);
permissions.allow('hundred5-support', 'candidate', [
  'read',
  'update',
  'delete',
]);
permissions.allow('job_opening_viewer', 'candidate', ['read']);
permissions.allow('job_opening_editor', 'candidate', ['update', 'delete']);

// RESOURCE: candidate notes
permissions.resource('candidate notes');
permissions.allow('viewer', 'candidate notes', ['read']);
permissions.allow('manager', 'candidate notes', ['create', 'update', 'delete']);
permissions.allow('hundred5-support', 'candidate notes', ['read']);
permissions.allow('job_opening_viewer', 'candidate notes', ['read']);
permissions.allow('job_opening_notes_only', 'candidate notes', [
  'update',
  'create',
  'delete',
]);

// RESOURCE: candidate ratings
permissions.resource('candidate ratings');
permissions.allow('viewer', 'candidate ratings', ['read']);
permissions.allow('admin', 'candidate ratings', ['update']);
permissions.allow('hundred5-support', 'candidate ratings', ['read', 'update']);
permissions.allow('job_opening_viewer', 'candidate ratings', ['read']);
permissions.allow('job_opening_editor', 'candidate ratings', ['update']);

// RESOURCE: user settings
permissions.resource('user settings');
permissions.allow('viewer', 'user settings', ['read', 'update']);

// RESOURCE: email notification
permissions.resource('email notification');
permissions.allow('owner', 'email notification', ['read', 'update']);
permissions.allow('admin', 'email notification', ['read', 'update']);
permissions.allow('manager', 'email notification', ['read', 'update']);
permissions.allow('viewer', 'email notification', ['read']);

// RESOURCE: gmail integration
permissions.resource('gmail integration');
permissions.allow('owner', 'gmail integration', ['read', 'update']);
permissions.allow('admin', 'gmail integration', ['read', 'update']);
permissions.allow('manager', 'gmail integration', ['read']);
permissions.allow('viewer', 'gmail integration', ['read']);
permissions.allow('hundred5-support', 'gmail integration', ['read']);

// RESOURCE: workspace settings
permissions.resource('workspace settings');
permissions.allow('manager', 'workspace settings', ['read']);
permissions.allow('admin', 'workspace settings', ['update']);
permissions.allow('hundred5-support', 'workspace settings', ['read', 'update']);
// RESOURCE: workspace settings

permissions.resource('workspace sso');
permissions.allow('admin', 'workspace sso', ['read', 'update']);
permissions.allow('hundred5-support', 'workspace sso', ['read', 'update']);

// RESOURCE: workspace integrations
permissions.resource('workspace integrations');
permissions.allow('manager', 'workspace integrations', ['read']);
permissions.allow('admin', 'workspace integrations', ['update']);
permissions.allow('hundred5-support', 'workspace integrations', [
  'read',
  'update',
]);

// RESOURCE: billing
permissions.resource('billing');
permissions.allow('admin', 'billing', ['read', 'update']);
permissions.allow('hundred5-support', 'billing', ['read', 'update']);

// RESOURCE: billing info
permissions.resource('billing info');
permissions.allow('admin', 'billing info', ['read']);
permissions.allow('owner', 'billing info', ['update']);
permissions.allow('hundred5-support', 'billing info', ['read', 'update']);

// RESOURCE: workspace invitations
permissions.resource('workspace invitations');
permissions.allow('admin', 'workspace invitations', [
  'read',
  'create',
  'update',
  'delete',
]);
permissions.allow('hundred5-support', 'workspace invitations', [
  'read',
  'create',
  'update',
  'delete',
]);

// RESOURCE: workspace members
permissions.resource('workspace members');
permissions.allow('manager', 'workspace members', ['read']);
permissions.allow('admin', 'workspace members', ['create', 'update', 'delete']);
permissions.allow('hundred5-support', 'workspace members', [
  'read',
  'create',
  'update',
  'delete',
]);

// RESOURCE: workspace members 2fa
permissions.resource('workspace members 2fa');
permissions.allow('owner', 'workspace members 2fa', ['read']);

// RESOURCE: workspace ownership transfer
permissions.resource('workspace ownership transfer');
permissions.allow('owner', 'workspace ownership transfer', [
  'create',
  'update',
  'delete',
]);

// RESOURCE: slack integration
permissions.resource('slack integration');
permissions.allow('manager', 'slack integration', ['read']);
permissions.allow('admin', 'slack integration', ['create', 'delete']);
permissions.allow('hundred5-support', 'slack integration', [
  'read',
  'create',
  'delete',
]);

// RESOURCE: ats integration
permissions.resource('ats integration');
permissions.allow('manager', 'ats integration', ['read']);
permissions.allow('admin', 'ats integration', ['create', 'delete']);
permissions.allow('hundred5-support', 'ats integration', [
  'read',
  'create',
  'delete',
]);

// RESOURCE: job ats integration settings
permissions.resource('job ats integration settings');
permissions.allow('manager', 'job ats integration settings', ['read']);
permissions.allow('admin', 'job ats integration settings', [
  'create',
  'update',
]);
permissions.allow('hundred5-support', 'job ats integration settings', [
  'read',
  'update',
]);
permissions.allow('job_opening_viewer', 'job ats integration settings', [
  'read',
]);
permissions.allow('job_opening_editor', 'job ats integration settings', [
  'create',
  'update',
]);

// RESOURCE: support
permissions.resource('support');
permissions.allow('hundred5-support', 'support', ['open']);

// RESOURCE: webhooks
permissions.resource('webhooks');
permissions.allow('admin', 'webhooks', ['read']);
permissions.allow('hundred5-support', 'webhooks', ['read']);

// RESOURCE: api keys
permissions.resource('api keys');
permissions.allow('admin', 'api keys', ['read']);
permissions.allow('hundred5-support', 'api keys', ['read']);
