import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton, DSContentBlock, DSField } from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import { number, object, string } from 'yup';

import {
  FormikInputField,
  FormikRadioField,
  Icon,
  pluralizeWithCount,
  PromptIfDirty,
  TimeInputWrapper,
} from '@/features/common';
import { useNotifications } from '@/features/notifications';
import { useJobOpeningPermission } from '@/features/permissions';
import {
  TestTimeUnit,
  useTestPage,
  useUpdateTestMutation,
} from '@/features/test';

import { TestSettingsSection } from '../test-settings-section';

interface Settings {
  name: string;
  timeSetting: 'auto' | 'manual';
  duration: number;
}

const validationSchema = object().shape({
  name: string().required("This can't be blank!"),
  timeSetting: string()
    .oneOf(['auto', 'manual'])
    .required("This can't be blank!"),
  duration: number().when('timeSetting', {
    is: 'manual',
    then: (schema) =>
      schema
        .required("This can't be blank!")
        .typeError('Time limit must be numeric value.')
        .min(1, 'Time limit must be greater than or equal to 1'),
    otherwise: (schema) => schema.nullable(),
  }),
});

export function SkillsTestSettingsGeneral() {
  const { addNotification } = useNotifications();
  const canUpdate = useJobOpeningPermission()('test', 'update');
  const updateTestMutation = useUpdateTestMutation();
  const { test } = useTestPage();

  const initialSettings: Settings = {
    name: test.name,
    timeSetting: test.durationInSeconds === 0 ? 'auto' : 'manual',
    duration:
      test.durationInSeconds === 0
        ? +(
            test.approximatedDurationInSeconds / TestTimeUnit.inSeconds
          ).toFixed(2)
        : test.durationInSeconds / TestTimeUnit.inSeconds,
  };

  const handleSubmit = async (values: Settings) => {
    const updatedTest = {
      name: values.name,
      durationInSeconds:
        values.timeSetting === 'auto'
          ? 0
          : values.duration * TestTimeUnit.inSeconds,
    };

    await updateTestMutation.mutateAsync({
      testId: test.id,
      test: updatedTest,
    });

    addNotification({ type: 'saved' });
  };

  return (
    <Formik<Settings>
      enableReinitialize
      initialValues={initialSettings}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, dirty, isSubmitting }) => (
        <Form>
          <PromptIfDirty />
          <DSContentBlock>
            <DSContentBlock.Title>General</DSContentBlock.Title>

            <DSContentBlock.Content>
              <TestSettingsSection>
                <TestSettingsSection.Content>
                  <NameInputField name="name" label="Test title" required />

                  <DSField label="Time limit" for="timeSetting">
                    <TestSettingsSection.OptionsWrapper>
                      <FormikRadioField
                        name="timeSetting"
                        value="auto"
                        label={
                          <TestSettingsSection.LabelWrapper>
                            Auto
                            <Icon
                              icon={regular('circle-info')}
                              color="purple-60"
                              fontSize="12px"
                              data-rh={`The Auto time limit allocates ${pluralizeWithCount(
                                1,
                                TestTimeUnit.unit
                              )} per question.`}
                              data-rh-at="right"
                            />
                          </TestSettingsSection.LabelWrapper>
                        }
                      />

                      <FormikRadioField
                        name="timeSetting"
                        value="manual"
                        label={
                          <TimeInputWrapper
                            duration={values.duration}
                            timeUnit={TestTimeUnit.unit}
                          >
                            <DurationInputField
                              name="duration"
                              disabled={values.timeSetting === 'auto'}
                              errorAbsolute
                            />
                          </TimeInputWrapper>
                        }
                        centered
                      />
                    </TestSettingsSection.OptionsWrapper>
                  </DSField>
                </TestSettingsSection.Content>
              </TestSettingsSection>
            </DSContentBlock.Content>

            <DSContentBlock.Footer>
              <DSButton
                type="submit"
                disabled={!canUpdate || !dirty || isSubmitting}
                data-rh={!canUpdate ? 'Only admins can save changes' : null}
              >
                {isSubmitting ? 'Saving...' : 'Save changes'}
              </DSButton>
            </DSContentBlock.Footer>
          </DSContentBlock>
        </Form>
      )}
    </Formik>
  );
}

const NameInputField = styled(FormikInputField)`
  max-width: 312px;
`;

const DurationInputField = styled(FormikInputField)`
  width: 116px;
`;
