import React from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';

import { useLogout } from '@/features/toggl-accounts';
import { useHistory } from '@/hooks/router';

export function NoWorkspace() {
  const history = useHistory();
  const logout = useLogout();

  return (
    <NoWorkspaceContainer>
      <Info>You are no longer part of any active Toggl Hire workspace.</Info>
      <Actions>
        <DSButton size="small" onClick={() => history.push('/admin/new')}>
          Create a workspace
        </DSButton>
        <DSButton size="small" variant="secondary" onClick={() => logout()}>
          Log out
        </DSButton>
      </Actions>
    </NoWorkspaceContainer>
  );
}

const NoWorkspaceContainer = styled.div`
  display: grid;
  gap: 16px;
  background: ${(props) => props.theme.colors.purple[100]};
  padding: 12px 16px;

  button {
    width: 100%;
  }
`;

const Info = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white};
`;

const Actions = styled.div`
  display: grid;
  gap: 8px;
`;
