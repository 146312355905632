import React from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import { useFormikContext } from 'formik';

import { ScheduleMenu } from '@/features/common';
import { IEmailModalFormValues, useEmailModalContext } from '@/features/email';

export const SubmitButtons = ({
  disabled,
  sending,
  ...props
}: {
  disabled: boolean;
  sending: boolean;
}) => {
  const { emailModal } = useEmailModalContext();
  const { submitForm, setFieldValue } =
    useFormikContext<IEmailModalFormValues>();

  if (emailModal === null) {
    return null;
  }

  switch (emailModal.type) {
    case 'rejection':
    case 'bulk-rejection': {
      return (
        <SubmitButtonsContainer {...props}>
          <DSButton
            type="button"
            variant="secondary"
            disabled={disabled}
            onClick={async () => {
              await setFieldValue('action', 'reject-without-email', false);
              await submitForm();
            }}
          >
            {sending ? 'Rejecting...' : 'Skip email & reject'}
          </DSButton>
          <SubmitButton
            type="button"
            disabled={disabled}
            onClick={async () => {
              await setFieldValue('action', 'send', false);
              await submitForm();
            }}
          >
            {sending ? 'Sending...' : ' Send & reject'}
          </SubmitButton>
          <ScheduleMenu
            disabled={disabled}
            onScheduleSend={async (time) => {
              await setFieldValue('sendAt', time);
              await submitForm();
            }}
          />
        </SubmitButtonsContainer>
      );
    }
    default: {
      return (
        <SubmitButtonsContainer {...props}>
          <SubmitButton
            type="button"
            name="send"
            disabled={disabled}
            onClick={async () => {
              await setFieldValue('action', 'send', false);
              await submitForm();
            }}
          >
            {sending ? 'Sending...' : 'Send'}
          </SubmitButton>
          <ScheduleMenu
            disabled={disabled}
            onScheduleSend={async (time) => {
              await setFieldValue('sendAt', time);
              await submitForm();
            }}
          />
        </SubmitButtonsContainer>
      );
    }
  }
};

const SubmitButtonsContainer = styled.div`
  display: flex;
`;

const SubmitButton = styled(DSButton)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 1px;
  margin-left: 8px;
`;
