import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton, DSInput } from '@hundred5/design-system';
import {
  editorStateToHtml,
  htmlToEditorState,
  ProseMirrorView,
} from '@togglhire/text-editor';
import { Field, useFormikContext } from 'formik';

import { Icon, TId } from '@/features/common';
import {
  createEditorState,
  CustomTextEditor,
  EditorState,
  parseTemplateVariables,
  serializeTemplateVariables,
} from '@/features/common/components/custom-text-editor';
import {
  convertVariablesFromCamelCase,
  convertVariablesToCamelCase,
  EmailTemplatesSelector,
  IEmailFormValuesBase,
  TEmailTemplateType,
  useEmailTemplateVariables,
  useSaveEmailTemplateMutation,
  useSelectedEmailTemplatesByTypeAndTestId,
} from '@/features/email';
import { GoogleRecaptchaPrivacyDisclaimer } from '@/features/google-recaptcha';
import { useInterviewQuery } from '@/features/interview';
import { useWorkspaceId } from '@/hooks/router';

import {
  SchedulingLinkSelect,
  VariablesSelect,
  SchedulingLinkTooltip,
} from './ui';

const MAX_SUBJECT_LENGTH: number = 255;

interface EmailFormProps extends React.HTMLAttributes<HTMLDivElement> {
  emailType: TEmailTemplateType;
  testId?: TId;
  jobOpeningId?: TId | null;
  interviewId: TId | null;
  tip?: string;
  readonlySubject?: boolean;
}

export const FormikEmailBody = ({
  emailType,
  testId,
  jobOpeningId,
  interviewId,
  tip,
  readonlySubject = false,
  ...props
}: EmailFormProps) => {
  const workspaceId = useWorkspaceId();
  const { emailTemplateVariablesUsedAreValid } = useEmailTemplateVariables();

  const [schedulingLinkSelected, setSchedulingLinkSelected] = useState('');

  const { data: interview } = useInterviewQuery(
    { interviewId: interviewId! },
    { enabled: !!interviewId }
  );

  const saveEmailTemplateMutation = useSaveEmailTemplateMutation();
  const [messageData, setMessageData] = useState(createEditorState(''));

  const handleVariableSelect = (
    proseMirrorView: ProseMirrorView,
    variable: string
  ) => {
    const variableName = variable.replace(/\[([^[]*)\]$/, '$1');
    const selection = proseMirrorView.state.selection;

    const node = proseMirrorView.state.schema.node(
      'template_variable',
      { variable: variableName },
      []
    );
    const tr = proseMirrorView.state.tr.insert(selection.from, node);

    proseMirrorView.dispatch(tr);
    proseMirrorView.focus();
  };

  const handleInterviewLinkSelect = (
    proseMirrorView: ProseMirrorView,
    link: string
  ) => {
    const linkName = link;
    const selection = proseMirrorView.state.selection;

    setSchedulingLinkSelected(link);

    const node = proseMirrorView.state.schema.node(
      'scheduling_link',
      { link: linkName },
      []
    );
    const tr = proseMirrorView.state.tr.insert(selection.from, node);

    proseMirrorView.dispatch(tr);
    proseMirrorView.focus();
  };

  const { setCurrentEmailTemplate, currentEmailTemplate } =
    useSelectedEmailTemplatesByTypeAndTestId(testId, emailType, jobOpeningId);

  const { values, setFieldValue, setValues, errors } =
    useFormikContext<IEmailFormValuesBase>();

  const isMessageOrSubjectEdited = !(
    values.message === currentEmailTemplate?.message &&
    values.subject === currentEmailTemplate?.subject
  );

  const onTextEditorChange = (state: EditorState) => {
    setMessageData(state);

    const html = serializeTemplateVariables(editorStateToHtml(state));
    setFieldValue('message', html, true);
  };

  useEffect(() => {
    if (!currentEmailTemplate) {
      return;
    }
    const message = parseTemplateVariables(currentEmailTemplate.message);
    const state = htmlToEditorState(message);
    setMessageData(state);
    const html = serializeTemplateVariables(editorStateToHtml(state));

    const subject = convertVariablesFromCamelCase(currentEmailTemplate.subject);

    setValues((prev) => ({
      ...prev,
      subject: readonlySubject ? prev.subject : subject,
      message: html,
    }));
  }, [currentEmailTemplate, setValues, readonlySubject]);

  const renderAboveEditorContent = (proseMirrorView: any) => {
    return (
      <AboveEditorContent>
        <div>
          <VariablesSelect
            onVariableSelect={(variable) => {
              handleVariableSelect(proseMirrorView, variable);
            }}
          />
        </div>
        {!!interview?.schedulingLink && (
          <>
            <div>
              <SchedulingLinkSelect
                interview={interview}
                onInterviewSelect={(variable) => {
                  handleInterviewLinkSelect(proseMirrorView, variable);
                }}
              />
            </div>

            <SchedulingLinkTooltip
              link={interview?.schedulingLink}
              type={interview?.name}
            />
          </>
        )}
        {tip && <Tip>{tip}</Tip>}
      </AboveEditorContent>
    );
  };

  return (
    <Container {...props}>
      <SubjectWrapper>
        <Field
          name="subject"
          as={DSInput}
          required
          placeholder={errors.subject ? 'Please add a subject line' : 'Subject'}
          maxLength={MAX_SUBJECT_LENGTH}
          value={values.subject}
          disabled={readonlySubject}
        />
        <EmailTemplatesSelectorContainer>
          <EmailTemplatesSelector
            onChange={(templateId) => {
              setCurrentEmailTemplate(templateId);
            }}
            currentEmailTemplateId={currentEmailTemplate?.id}
            jobOpeningId={jobOpeningId}
          />
        </EmailTemplatesSelectorContainer>
      </SubjectWrapper>
      <BodyWrapper>
        <TextEditor
          state={messageData}
          onChange={onTextEditorChange}
          enableTemplateVariables
          enableSchedulingLink
          renderAboveEditorContent={renderAboveEditorContent}
        />
        <BodyHeader>{tip && <Tip>{tip}</Tip>}</BodyHeader>
        {isMessageOrSubjectEdited && (
          <SaveTemplateButton
            variant="secondary"
            size="small"
            Icon={<Icon icon={regular('floppy-disk')} />}
            disabled={schedulingLinkSelected !== ''}
            data-rh={
              schedulingLinkSelected
                ? 'You cannot save a template that includes a scheduling link'
                : null
            }
            onClick={async () => {
              const subject = convertVariablesToCamelCase(values.subject);

              if (
                emailTemplateVariablesUsedAreValid(subject) &&
                emailTemplateVariablesUsedAreValid(values.message)
              ) {
                try {
                  const savedTemplate =
                    await saveEmailTemplateMutation.mutateAsync({
                      workspaceId,
                      name: subject,
                      subject: subject,
                      message: values.message,
                      type: emailType,
                      status: 'active',
                      candidateTags: [],
                    });
                  setCurrentEmailTemplate(savedTemplate.id);
                } catch {}
              }
            }}
          >
            Save as new template
          </SaveTemplateButton>
        )}
      </BodyWrapper>
      <GoogleRecaptchaPrivacyDisclaimer />
    </Container>
  );
};

const SubjectWrapper = styled.div`
  display: flex;
  gap: 12px;
`;
const BodyWrapper = styled.div`
  position: relative;
`;

const AboveEditorContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 12px 12px 0;
`;

const BodyHeader = styled.div`
  position: absolute;
  top: 56px;
  left: 12px;
  display: flex;
  gap: 12px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Tip = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.purple[100]};
  padding: 3px 16px;
  background: ${({ theme }) => theme.colors.orange[20]};
  border-radius: 8px;
`;

const SaveTemplateButton = styled(DSButton)`
  padding-left: 12px;
  padding-right: 12px;
  position: absolute;
  bottom: 12px;
  right: 20px;
  z-index: 0;
  display: flex;
  gap: 8px;
`;

const EmailTemplatesSelectorContainer = styled.div`
  width: 144px;
`;

const TextEditor = styled(CustomTextEditor)`
  border: 1px solid ${({ theme }) => theme.colors.purple[40]};
  border-radius: 8px;
  max-height: 320px;

  [contenteditable='false'] {
    color: ${({ theme }) => theme.colors.purple[100]};
  }

  & > :first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 8px 12px;
    background-color: ${(props) => props.theme.hover.onWhite};
    margin-top: 0;
  }

  & > :nth-child(2) {
    padding: 12px 12px 0;
  }

  & > :nth-child(3) {
    margin-top: 0;
    padding: 4px 12px 12px;
  }
`;
