import React from 'react';
import { format } from 'date-fns/format';

import {
  contactSupport,
  NotificationLink,
  reportError,
  TNotificationContent,
} from '@/features/notifications';

type TNotificationDefaultSettings = {
  kind: TNotificationContent['kind'];
  stackable: TNotificationContent['stackable'];
  autoCloseTimeout?: TNotificationContent['autoCloseTimeout'];
};

const successSettings: TNotificationDefaultSettings = {
  kind: 'success',
  stackable: true,
  autoCloseTimeout: 3000,
};

const warningSettings: TNotificationDefaultSettings = {
  kind: 'warning',
  stackable: true,
  autoCloseTimeout: 5000,
};

const failureSettings: TNotificationDefaultSettings = {
  kind: 'failure',
  stackable: true,
};

export const NOTIFICATION_DEFAULTS: {
  [T in TNotificationContent['type']]: Omit<
    Extract<TNotificationContent, { type: T }>,
    'type' | 'meta'
  >;
} = {
  // region failure

  unexpected: {
    ...failureSettings,
    title: 'Something went wrong.',
    description: 'Please try again or report the error to our support team.',
    actionLabel: 'Report',
    onAction: (meta) => reportError(meta.errorId),
  },

  bad_request: {
    ...failureSettings,
    title: 'Could not perform this action',
  },

  invalid: {
    ...failureSettings,
    title: 'Input is not valid',
  },

  invalid_token: {
    ...failureSettings,
    title: 'Invalid confirmation link.',
    description: 'Confirmation link you supplied is invalid or expired.',
  },

  invalid_workspace_invitation_email_error: {
    ...failureSettings,
    title: 'Unable to invite this user.',
    description: 'Please check you have the correct email address.',
  },

  verification_email_error: {
    ...failureSettings,
    title: 'Verification process failed.',
    description: (meta) =>
      `${meta.errorDescription}. Please try again or report the error to our support team.`,
    actionLabel: 'Report',
    onAction: (meta) => reportError(meta.error),
  },

  email_sending_failed: {
    ...failureSettings,
    title: 'Email sending failed.',
    description: (meta) => meta.error,
  },

  email_bad_template: {
    ...failureSettings,
    title: 'Email sending failed.',
    description: (meta) =>
      meta.error ||
      'Please check if the variables used in the email template are defined in the variable dropdown menu.',
  },

  third_party_login_error_email_not_provided: {
    ...failureSettings,
    title: 'Email missing.',
    description: (meta) => {
      const providerNames = { linkedin: 'LinkedIn' };
      return `Please add a verified email address to your ${
        providerNames[meta.provider]
      } account and try again.`;
    },
  },

  third_party_login_error_user_not_using_provider: {
    ...failureSettings,
    title: 'User Already Exists.',
    description:
      'You have previously signed up using an email address and password. Please log in.',
    actionLabel: 'Log In',
    onAction: () => (window.location.href = `${window.location.origin}/admin`),
  },

  gmail_auth_error: {
    ...failureSettings,
    title: 'Unable to connect your Gmail account.',
    description: `Please try again or report the error to our support team. `,
    actionLabel: 'Report',
    onAction: () => reportError('gmail_auth_error'),
  },

  stage_with_candidates: {
    ...failureSettings,
    title: 'Unable to delete stage.',
    description: 'Stages with candidates cannot be deleted.',
  },

  spam_email_detected: {
    ...failureSettings,
    title: 'Unable to send email.',
    description:
      'Our system detected suspicious activity on your account. If you think this is an error, please contact us.',
    actionLabel: 'Contact support',
    onAction: () => contactSupport(),
  },

  // region warning

  forbidden: {
    ...warningSettings,
    title: 'You do not have permission',
  },

  not_found: {
    ...warningSettings,
    title: 'This has been deleted',
  },

  rate_limited: {
    ...warningSettings,
    title: 'This action is rate-limited',
  },

  network_error: {
    ...warningSettings,
    title: "You're offline",
    stackable: false,
  },

  recaptcha_error: {
    ...warningSettings,
    title: 'reCaptcha error.',
    description:
      "We couldn't connect with reCaptcha service. Try checking your network connection.",
  },

  email_already_verified: {
    ...warningSettings,
    title: 'Already verified.',
    description: 'Your email address has already been verified.',
  },

  candidate_contact_info_set_to_original: {
    ...warningSettings,
    title: (meta) => meta.fieldName,
    description: (meta) =>
      ` has been reverted to the original value provided by the candidate at the time of application${
        meta.mandatory
          ? " because it's a mandatory field and can't be empty"
          : ''
      }`,
  },

  email_limit_exceeded: {
    ...warningSettings,
    title: 'Limit exceeded.',
    description: (
      <>
        You have exceeded your{' '}
        <NotificationLink
          href="https://support.hire.toggl.com/en/articles/10922722-email-sending-limits-in-toggl-hire"
          rel="noreferrer noopener"
          target="_blank"
        >
          daily email quota
        </NotificationLink>
        .
      </>
    ),
  },

  test_invitation_resend_limit_reached: {
    ...warningSettings,
    title: 'Limit reached.',
    description:
      'You have reached the limit on test invitation resends. You can only resend the invitation 5 times.',
  },

  tag_length_exceeded: {
    ...warningSettings,
    title: (meta) => `Tag length is limited to ${meta.maxLength} characters`,
  },

  tag_restricted: {
    ...warningSettings,
    title: (meta) => (
      <>
        <i>{meta.tag}</i> is a reserved system tag, please use another wording
        for your tag
      </>
    ),
  },

  email_template_variable_invalid: {
    ...warningSettings,
    title: (meta) =>
      `The variable ${meta.variable} in your template can't be used.`,
    description:
      'Try replacing it with one of the variables from the dropdown menu.',
  },

  coupon_limit_reached: {
    ...warningSettings,
    title: 'Invalid promo code.',
    description: 'Coupon has reached max redemptions for this account.',
  },

  unsupported_file_format: {
    ...warningSettings,
    title: 'Unsupported file type.',
    description: (meta) =>
      `This file format isn't supported. Please use one of the following file formats: ${meta.supportedFormats}.`,
  },

  unsupported_file_size: {
    ...warningSettings,
    title: 'File too large.',
    description: (meta) =>
      `The file size is limited to ${meta?.supportedSizeMB} MB, please check the size of the file you are trying to upload and try again.`,
  },

  email_import_invalid_format: {
    ...warningSettings,
    title: 'Invalid email format.',
    description: (meta) =>
      `${meta.fileName} contains invalid email addresses on line${
        meta.errorLines.length > 1 ? 's:' : ''
      } ${meta.errorLines
        .slice()
        .join(
          ', '
        )}. Please correct the email addresses and upload the file again.`,
  },

  email_import_limit_exceeded: {
    ...warningSettings,
    title: 'Limit exceeded.',
    description:
      'You can only upload 50 emails at once. If you want to send invitations to more candidates, please split your address list into batches of 50 email addresses.',
  },

  gmail_mailbox_already_connected: {
    ...warningSettings,
    title: 'Unable to connect your Gmail account',
    description: 'This Gmail account is connected to a different Toggl account',
  },

  // region success

  saved: {
    ...successSettings,
    title: 'Changes saved',
  },

  candidate_unlocked: {
    ...successSettings,
    title: 'Candidate unlocked',
  },

  candidate_added: {
    ...successSettings,
    title: 'Candidate added',
  },

  candidate_rejected: {
    ...successSettings,
    title: 'Candidate rejected',
  },

  candidates_rejected: {
    ...successSettings,
    title: 'Candidates rejected',
  },

  candidate_restored: {
    ...successSettings,
    title: 'Candidate restored',
  },

  copied_to_clipboard: {
    ...successSettings,
    title: 'Copied to clipboard',
  },

  thanks_for_feedback: {
    ...successSettings,
    title: 'Thank you for your feedback!',
  },

  invite_sent: {
    ...successSettings,
    title: 'Invitation email has been sent',
  },

  email_sent: {
    ...successSettings,
    title: 'Email has been sent',
  },

  emails_sent: {
    ...successSettings,
    title: 'Emails have been sent',
  },

  email_scheduled: {
    ...successSettings,
    title: (meta) =>
      `The email is scheduled to be sent on ${format(
        meta.date,
        'MMM d, yyyy hh:mmaa'
      )}.`,
    description: "You can review it in the candidate's history tab.",
    autoCloseTimeout: 5000,
  },

  emails_scheduled: {
    ...successSettings,
    title: (meta) =>
      `The emails are scheduled to be sent on ${format(
        meta.date,
        'MMM d, yyyy hh:mmaa'
      )}.`,
    description: "You can review it in the candidate's history tab.",
    autoCloseTimeout: 5000,
  },

  verification_email_sent: {
    ...successSettings,
    title: 'Verification email has been sent',
  },

  email_verified: {
    ...successSettings,
    title: 'Email verified.',
    description: 'Your email address has been successfully verified.',
  },

  position_request_sent: {
    ...successSettings,
    title: 'Your request for a new position has been sent',
  },

  email_template_preview_sent: {
    ...successSettings,
    title: 'Test template is sent to your email address',
  },

  detect_ai_answer_and_add_note: {
    ...successSettings,
    title:
      "AI detection has been completed and the result has been added to the answer's notes",
  },

  evaluate_ai_answer_and_add_note: {
    ...successSettings,
    title:
      "AI evaluation has been completed and the result has been added to the answer's notes",
  },

  gmail_synced: {
    ...successSettings,
    title: 'Import completed',
  },

  gmail_email_deleted: {
    ...successSettings,
    title: 'Email deleted',
  },

  gmail_user_data_deleted: {
    ...successSettings,
    title: 'Data deletion completed',
  },

  subscription_paused: {
    ...successSettings,
    title: 'Subscription paused.',
    description: 'Your subscription has been paused successfully.',
  },

  file_uploaded: {
    ...successSettings,
    title: 'File has been successfully uploaded',
  },

  free_trial_activation: {
    ...successSettings,
    title: 'Your free 14 day Starter trial has started!',
  },

  plan_updated: {
    ...successSettings,
    title: 'Plan updated',
  },

  automation_action_success: {
    ...successSettings,
    title: (meta) => `Automation ${meta.action}`,
  },
};
