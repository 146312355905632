import React, { useCallback } from 'react';

import { TId, ConfirmModal } from '@/features/common';
import { TestType, TTestType, useTestQuery } from '@/features/test';

interface DeleteTestModalProps {
  onClose: () => void;
  onDelete: (categoryId: TId) => void;
  open: boolean;
  testId?: TId;
}

export function DeleteTestModal({
  onClose,
  onDelete,
  open,
  testId,
}: DeleteTestModalProps) {
  const testQuery = useTestQuery({ testId: testId });
  const handleConfirm = useCallback(() => {
    if (!testId) {
      return;
    }

    onDelete(testId);
    onClose();
  }, [testId, onDelete, onClose]);

  const getTestTypeText = (testType?: TTestType) => {
    if (testType === TestType.Quiz) {
      return 'test';
    } else if (testType === TestType.Video) {
      return 'async interview';
    } else if (testType === TestType.Homework) {
      return 'homework';
    }
    return 'test';
  };

  return (
    <ConfirmModal
      open={open}
      action="Delete test"
      layer={1000}
      confirmation="delete"
      onConfirm={handleConfirm}
      onClose={onClose}
      title="Delete test"
      footerVariant="destructive-primary"
    >
      <p>
        Are you sure you want to delete this{' '}
        <strong>{getTestTypeText(testQuery.data?.type)}</strong>?
      </p>
      <p>
        If any candidates have completed this test, this will also delete their{' '}
        <strong>
          {testQuery.data?.type === TestType.Homework
            ? 'results'
            : 'test scores'}
        </strong>
        .
      </p>
    </ConfirmModal>
  );
}
