import React from 'react';

export const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M11.9993 18.4475C10.5116 18.447 9.07467 17.9068 7.95501 16.9272C6.83536 15.9477 6.10905 14.5952 5.91084 13.1208C5.71264 11.6464 6.05599 10.1501 6.8772 8.90964C7.69841 7.66916 8.94171 6.76868 10.3764 6.37528V8.16396C9.4115 8.54224 8.60895 9.24559 8.10735 10.1526C7.60575 11.0595 7.43659 12.1132 7.62908 13.1316C7.82157 14.15 8.36362 15.0692 9.16165 15.7305C9.95967 16.3918 10.9636 16.7537 12 16.7537C13.0364 16.7537 14.0403 16.3918 14.8384 15.7305C15.6364 15.0692 16.1784 14.15 16.3709 13.1316C16.5634 12.1132 16.3943 11.0595 15.8927 10.1526C15.3911 9.24559 14.5885 8.54224 13.6236 8.16396V6.37528C15.0584 6.76871 16.3018 7.6693 17.123 8.90993C17.9442 10.1506 18.2875 11.647 18.0891 13.1215C17.8907 14.596 17.1641 15.9485 16.0442 16.9279C14.9243 17.9074 13.4871 18.4473 11.9993 18.4475ZM11.1174 4.32242H12.8826V13.0787H11.1174V4.32242ZM11.9993 7.88944e-08C9.62597 0.000136142 7.30596 0.704039 5.33266 2.0227C3.35936 3.34136 1.8214 5.21555 0.913253 7.40827C0.00510742 9.601 -0.232434 12.0138 0.230666 14.3415C0.693766 16.6692 1.83671 18.8074 3.51497 20.4855C5.19322 22.1637 7.33142 23.3065 9.65917 23.7695C11.9869 24.2324 14.3997 23.9948 16.5924 23.0865C18.785 22.1782 20.6591 20.6401 21.9777 18.6668C23.2962 16.6934 24 14.3733 24 12C24.0001 10.4241 23.6897 8.86352 23.0867 7.40753C22.4836 5.95154 21.5996 4.6286 20.4852 3.51427C19.3708 2.39994 18.0479 1.51605 16.5918 0.913064C15.1358 0.31008 13.5753 -0.000180631 11.9993 7.88944e-08Z"
      fill="#e57cd8"
    />
  </svg>
);
