import styled from '@emotion/styled';

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
