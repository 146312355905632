import React from 'react';
import { usePostHog } from 'posthog-js/react';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import {
  IQuestion,
  useCreateCustomQuestionMutation,
} from '@/features/questions';
import { TEditableQuestion, useTestPage } from '@/features/test';
import { useHistory } from '@/hooks/router';

import { CustomQuestionEditor } from '../question/custom-question-editor';

const BAREBONE_QUESTION: TEditableQuestion = {
  source: 'custom',
  questionType: 'single-choice',
  points: 1,
  options: [
    {
      option: 'Choice 1',
      isCorrect: false,
    },
  ],
};

export const CreateCustomQuestion = ({
  index,
  onCancel,
}: {
  index: number;
  onCancel: () => void;
}) => {
  const { workspace, jobOpening, test } = useTestPage();
  const history = useHistory();
  const isVideoTest = test.type === 'video';
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();

  const createQuestionMutation = useCreateCustomQuestionMutation({
    testId: test.id,
    onSuccess: (question: IQuestion) => {
      amplitude?.logEvent('question creation/create custom question', {
        'job opening id': jobOpening.id,
        'test id': test.id,
      });
      posthog?.capture(
        'hire_admin_app:question creation/create custom question',
        {
          'job opening id': jobOpening.id,
          'test id': test.id,
        }
      );
      history.replace(
        `/admin/${workspace.id}/openings/${jobOpening.id}/test/${test.id}/questions/${question.id}${history.location.search}`
      );
    },
  });

  const handleSave = async (question: IQuestion) => {
    await createQuestionMutation.mutateAsync({
      testId: test.id,
      question,
    });
  };

  return (
    <CustomQuestionEditor
      index={index}
      question={{
        ...BAREBONE_QUESTION,
        questionType: isVideoTest
          ? 'video-question'
          : BAREBONE_QUESTION.questionType,
      }}
      onCancel={onCancel}
      onSave={handleSave}
    />
  );
};
