import styled from '@emotion/styled';

export const ModalHeader = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => props.theme.typography.colorPrimary};
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
`;

export const ModalHeaderDescription = styled.div`
  color: ${(props) => props.theme.typography.colorPrimary};
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 16px;
`;

export const ModalBody = styled.div`
  padding: 16px 0;
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const ModalFooterButtons = styled.div`
  display: flex;
  gap: 8px;
`;

export const ModalFooter = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: flex-end;
`;
