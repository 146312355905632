import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export function LogoHire() {
  return (
    <LogoContainer to={`/`}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.55539e-05 12.0027C-0.0105688 5.37686 5.38215 -0.0105688 12.0132 1.55691e-05C18.6338 0.00530774 24.0106 5.39274 24 12.0238C23.9894 18.639 18.6073 24.0212 11.9709 24.0053C5.35569 23.9894 1.55539e-05 18.6285 1.55539e-05 12.0027ZM18.9089 11.1083C18.8507 11.1083 18.8031 11.1083 18.7502 11.1083C16.8556 11.1083 14.9557 11.1083 13.0611 11.1083C12.8917 11.1083 12.8917 11.1083 12.8917 10.9389C12.8917 9.04434 12.8917 7.14445 12.8917 5.24985C12.8917 5.19693 12.8865 5.1493 12.8865 5.09638C12.2937 5.09638 11.7063 5.09638 11.1083 5.09638C11.1083 5.15459 11.1083 5.20751 11.1083 5.25514C11.1083 7.14974 11.1083 9.03905 11.1083 10.9336C11.1083 10.9707 11.1136 11.0077 11.103 11.0395C11.0924 11.0659 11.0659 11.0924 11.0395 11.103C11.0077 11.1136 10.9707 11.1083 10.9336 11.1083C9.03905 11.1083 7.13916 11.1083 5.24456 11.1083C5.19164 11.1083 5.14401 11.1136 5.08579 11.1136C5.08579 11.1665 5.08579 11.2035 5.08579 11.2406C5.08579 11.7381 5.08579 12.2355 5.08579 12.733C5.08579 12.8865 5.04875 12.8865 5.24456 12.8865C7.13916 12.8865 9.02846 12.8865 10.9231 12.8865C10.9601 12.8865 10.9971 12.8865 11.0289 12.8865C11.0818 12.8865 11.103 12.9129 11.103 12.9658C11.103 13.0029 11.103 13.0399 11.103 13.0717C11.103 14.9663 11.103 16.8556 11.103 18.7502C11.103 18.7978 11.1083 18.8507 11.1083 18.9037C11.7063 18.9037 12.2831 18.9037 12.8759 18.9037C12.8759 18.8507 12.8812 18.7978 12.8812 18.7449C12.8812 16.8503 12.8812 14.961 12.8812 13.0664C12.8812 13.0293 12.8706 12.9923 12.8865 12.9605C12.897 12.9341 12.9288 12.9023 12.9553 12.8917C12.987 12.8812 13.0241 12.8865 13.0611 12.8865C14.9557 12.8865 16.845 12.8865 18.7396 12.8865C18.7925 12.8865 18.8401 12.8865 18.8984 12.8865C18.9089 12.2937 18.9089 11.7116 18.9089 11.1083Z"
          fill="#E87161"
        />
      </svg>
    </LogoContainer>
  );
}

const LogoContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
`;
