import React from 'react';
import styled from '@emotion/styled';
import { usePostHog } from 'posthog-js/react';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { ConfirmModal, TId } from '@/features/common';
import { useDeleteInterviewMutation } from '@/features/interview';
import { useJobOpeningId, useWorkspaceId } from '@/hooks/router';

interface DeleteInterviewModalProps {
  interviewId: TId;
  open: boolean;
  onClose: () => void;
  onDeleteSuccess: () => void;
}

export function DeleteInterviewModal({
  interviewId,
  open,
  onClose,
  onDeleteSuccess,
}: DeleteInterviewModalProps) {
  const workspaceId = useWorkspaceId();
  const jobOpeningId = useJobOpeningId();
  const deleteInterviewMutation = useDeleteInterviewMutation({ jobOpeningId });
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();

  const handleConfirm = async () => {
    await deleteInterviewMutation.mutateAsync({ interviewId });

    amplitude?.logEvent('interview/delete', {
      'workspace id': workspaceId,
      'job opening id': jobOpeningId,
      'interview id': interviewId,
    });
    posthog?.capture('hire_admin_app:interview/delete', {
      'workspace id': workspaceId,
      'job opening id': jobOpeningId,
      'interview id': interviewId,
    });
    onDeleteSuccess();
  };

  return (
    <ConfirmModal
      open={open}
      action="Delete"
      onConfirm={handleConfirm}
      onClose={onClose}
      confirmation="delete"
      title="Delete interview"
      footerVariant="destructive-primary"
    >
      <SubTitle>
        Are you sure your want to delete this <strong>interview</strong>?
      </SubTitle>
      <p>
        If a candidate has taken this interview, this will delete their{' '}
        <strong>interview notes</strong>.
      </p>
    </ConfirmModal>
  );
}

const SubTitle = styled.p`
  margin: 24px 0 12px 0;
  font-weight: 700;

  @media all and (max-width: 768px) {
    margin: 16px 0 24px 0;
  }
`;
