import React from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSGrid } from '@hundred5/design-system';

import { Icon } from '@/features/common';
import { ActionBanner } from '@/features/common/components/action-banner/action-banner';
import {
  PersonalInfo,
  ProfileActions,
  ProfileSettings,
  ProfileSettingsActions,
  ProfileSettingsGmail,
} from '@/features/me';
import { ProfileSettingsSSO } from '@/features/me/components/profile-settings-sso';
import { useWorkspacePermission } from '@/features/permissions';
import { useWorkspaceTreatments } from '@/features/splits';
import { getSharedAuthEnabled } from '@/features/toggl-accounts';
import { WorkspaceSettingsPage } from '@/features/workspace';
import { useWorkspaceIdOrNull } from '@/hooks/router';

const ProfileSettingsPage = () => {
  const workspaceId = useWorkspaceIdOrNull();
  const sharedAuthEnabled = getSharedAuthEnabled();
  const ssoPermission = useWorkspacePermission()('workspace sso', 'read');
  const { treatments } = useWorkspaceTreatments(['fe_admin_app_emails_tab']);

  // TODO: adding temporary workspaceId check in case /admin/profile is accessed without workspaceId, then Gmail settings component is breaking
  const isEmailIntegrationHidden =
    !workspaceId || treatments.fe_admin_app_emails_tab.treatment === 'off';

  return (
    <WorkspaceSettingsPage title="Profile settings">
      <DSGrid gap="20px">
        <ActionBanner
          icon={
            <Icon
              icon={solid('briefcase-blank')}
              color="white"
              fontSize="18px"
            />
          }
          title="First, create a job"
          subTitle="Start assessing candidates in less than 2 minutes"
          actionTitle="Get started"
          isGetStartedBanner
        />
        {sharedAuthEnabled ? (
          <ProfileSettings />
        ) : (
          <>
            <PersonalInfo />
            <ProfileActions />
          </>
        )}

        {ssoPermission && <ProfileSettingsSSO />}
        {!isEmailIntegrationHidden && <ProfileSettingsGmail />}
        {sharedAuthEnabled && <ProfileSettingsActions />}
      </DSGrid>
    </WorkspaceSettingsPage>
  );
};

export default ProfileSettingsPage;
