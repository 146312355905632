import styled from '@emotion/styled';

export const UnitInputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  ::after {
    display: block;
    position: absolute;
    right: 12px;
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.typography.colorSecondary};
  }

  :has(input:disabled) {
    ::after {
      opacity: 0.4;
    }
  }

  input {
    padding-right: 50px;
  }
`;
