import React from 'react';
import styled from '@emotion/styled';
import { DSModal } from '@hundred5/design-system';
import { useMedia } from 'react-use';

import { EmailResultModalContextProvider } from '../../email/components/email-result-modal/email-result-modal';
import { useShareModal } from '../hooks';
import JobOpeningShareLink from '../ui/job-opening-share-link';
import Selectors from '../ui/selectors';

import { ShareModalEmailForm } from './email-form';

//region Main
const ShareModal = () => {
  const { isShareModalOpen, closeShareModal } = useShareModal();

  const isMobile = useMedia('(max-width: 768px)');

  return (
    <DSModal
      open={isShareModalOpen}
      shouldCloseOnOverlayClick={false}
      onClose={closeShareModal}
      contentStyle={{
        width: !isMobile && '644px',
        padding: 0,
        maxHeight: !isMobile && 'calc(100vh - 70px)',
      }}
      scrollableWithStickyComponents={!isMobile}
      fluid
      scrollable={isMobile}
    >
      <Content initialized={true}>
        {isMobile ? (
          <>
            <MobileHeader>
              <CancelButton onClick={() => closeShareModal()}>
                Cancel
              </CancelButton>
              <ShareLinkTitle>Add Candidate</ShareLinkTitle>
              <MobileCopyButton>Copy link</MobileCopyButton>
            </MobileHeader>
            <SelectorWrapper>
              <Selectors />
            </SelectorWrapper>
          </>
        ) : (
          <StickyHeader>
            <ShareLinkTitle>Add Candidate</ShareLinkTitle>
            <DSModal.CloseButton onClick={closeShareModal} />
            <Header>
              <Selectors />
            </Header>
          </StickyHeader>
        )}
        <EmailResultModalContextProvider>
          <ShareModalEmailForm />
        </EmailResultModalContextProvider>
      </Content>
    </DSModal>
  );
};
//endregion

//region Styles
const Content = styled.div<{ initialized: boolean }>`
  position: relative;
  visibility: ${(props) => !props.initialized && 'hidden'};
  background: #ffffff;
  border-radius: 8px;
`;

const Header = styled.div`
  background: ${(props) => props.theme.colors.purple[5]};
  padding: 16px 36px 16px 36px;

  @media all and (max-width: 768px) {
    display: flex;
    width: 100%;
    padding: 24px 16px 16px 16px;
    justify-content: space-between;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => props.theme.typography.colorPrimary};
    align-items: center;
    text-align: center;
    border-bottom: 1px solid ${(props) => props.theme.colors.purple[10]};
  }
`;

const MobileHeader = styled.div`
  @media all and (max-width: 768px) {
    display: flex;
    width: 100%;
    padding: 24px 16px 16px 16px;
    justify-content: space-between;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => props.theme.typography.colorPrimary};
    align-items: center;
    text-align: center;
    border-bottom: 1px solid ${(props) => props.theme.colors.purple[10]};
  }
`;

const CancelButton = styled.button`
  all: unset;
  font-weight: 500;
  font-size: 14px;
`;

const MobileCopyButton = styled(JobOpeningShareLink)`
  display: none;

  @media all and (max-width: 768px) {
    all: unset;
    font-weight: 500;
    font-size: 14px;
    display: initial;

    &:hover {
      background-color: ${(props) => props.theme.colors.white};
    }

    svg {
      display: none;
    }
  }
`;

const SelectorWrapper = styled.div`
  background: ${(props) => props.theme.colors.purple[5]};
  padding: 16px 36px 16px 36px;

  @media all and (max-width: 768px) {
    padding: 16px;
  }
`;

const StickyHeader = styled.div`
  position: sticky;
  top: -44px;
  z-index: 999;
  border-radius: 8px;
  overflow: visible;
`;

const ShareLinkTitle = styled.h3`
  padding-top: 36px;
  padding-left: 36px;
  padding-bottom: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.purple[100]};
  margin: 0;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;

  @media all and (max-width: 768px) {
    padding: 0;
  }
`;

//endregion

export default ShareModal;
