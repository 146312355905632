import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { ME_QUERY_KEY } from '@/features/me';

import { finishOnboarding } from '../api';

export const useFinishOnboardingMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(finishOnboarding, {
    onSuccess: () => {
      queryClient.invalidateQueries([...ME_QUERY_KEY]);
    },
    ...(options as any),
  });
};
