import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
  DSButton,
  DSColumn,
  DSField,
  DSIconInfo,
  DSInput,
  DSInputMultiple,
  DSRow,
} from '@hundred5/design-system';
import { useField } from 'formik';
import { find, uniqueId } from 'lodash';
import { usePostHog } from 'posthog-js/react';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { TId } from '@/features/common';
import { useNotifications } from '@/features/notifications';
import UploadHint from '@/features/share/ui/upload-hint';
import { useWorkspaceId } from '@/hooks/router';
import { isEmail } from '@/utils/emails';

type Props = {
  name: string;
  label: React.ReactNode;
  required?: boolean;
  id?: string;
  type?: string;
  placeholder?: string;
  readonly?: boolean;
  disabled?: boolean;
  errorAbsolute?: boolean;
  className?: string;
  disableFileInput?: boolean;
} & React.ComponentProps<typeof DSInput>;

interface IInputMultipleItems {
  id: TId;
  text: string; // fileName or email
  error: boolean;
  meta?: { emails: string[] };
}

const VALID_EXTENSIONS = ['.csv', '.txt'];

export function FormikInputMultipleEmailsField({
  name,
  label,
  required,
  id = uniqueId('formik-field-'),
  placeholder,
  disabled,
  errorAbsolute,
  className,
  disableFileInput,
}: Props) {
  const [field, meta, helpers] = useField<any>(name);
  const error = meta.touched && !!meta.error ? meta.error : null;
  const [inputMultipleItems, setInputMultipleItems] = useState<
    IInputMultipleItems[]
  >([]);
  const workspaceId = useWorkspaceId();
  const fileUploadInput = useRef<HTMLInputElement>(null);
  const { addNotification } = useNotifications();
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();

  // TODO: remove this as soon as the client sends all the invitation emails
  const maxEmailsPerFile = workspaceId === '21148' ? 200 : 50;

  const handleFileChange = (event) => {
    const files = event.target.files;

    for (let file of files) {
      const fileExtension = file.name
        .substr(file.name.lastIndexOf('.'))
        .toLowerCase();
      const fileReader = new FileReader();
      const id = file.name.toLowerCase();

      if (find(files, { id }) || find(inputMultipleItems, { id })) {
        return;
      }

      if (VALID_EXTENSIONS.indexOf(fileExtension) < 0) {
        addNotification({
          type: 'unsupported_file_format',
          meta: { supportedFormats: VALID_EXTENSIONS.join(', ') },
        });

        setInputMultipleItems((state) => [
          ...state,
          {
            id,
            text: file.name,
            error: false,
            meta: { emails: [] },
          },
        ]);

        return;
      }

      fileReader.readAsBinaryString(file);

      fileReader.addEventListener('load', (e) => {
        if (e.target?.result) {
          const rawList = (e.target.result as string).split('\n');
          const fileEmails = new Set<string>();
          const errorLines: number[] = [];

          for (let [index, email] of rawList.entries()) {
            email = email.trim();

            if (!isEmail(email)) {
              if (index === 0 || email === '') {
                continue;
              } else {
                errorLines.push(index + 1);
              }
            }

            fileEmails.add(email);
          }

          setInputMultipleItems((state) => [
            ...state,
            {
              id: file.name.toLowerCase(),
              text: file.name,
              meta: { emails: [...fileEmails] },
              error: !!errorLines.length || fileEmails.size > maxEmailsPerFile,
            },
          ]);

          helpers.setValue([...field.value, ...fileEmails]);

          if (fileEmails.size > maxEmailsPerFile) {
            addNotification({ type: 'email_import_limit_exceeded' });
          }

          if (errorLines.length) {
            addNotification({
              type: 'email_import_invalid_format',
              meta: { fileName: file.name, errorLines },
            });
          }
        }
      });
    }
  };

  return (
    <DSRow gap="16px" smWrap>
      <DSColumn size={disableFileInput ? 24 : 12} sm={24}>
        <DSField
          for={id}
          label={label}
          required={required}
          error={error}
          errorAbsolute={errorAbsolute}
          className={className}
        >
          <DSInputMultiple
            disabled={disabled}
            placeholder={placeholder}
            emailValidation={true}
            onChipChange={(items) => {
              setInputMultipleItems(items);
              helpers.setValue([
                ...items.map((item) => item.meta?.emails).flat(),
              ]);
            }}
            items={inputMultipleItems}
          />
        </DSField>
      </DSColumn>
      {disableFileInput ? null : (
        <DSColumn size={12} sm={24}>
          <ButtonWrapper>
            <DSButton
              variant="secondary"
              onClick={() => {
                amplitude?.logEvent('emails/upload emails button click', {
                  'workspace id': workspaceId,
                });
                posthog?.capture(
                  'hire_admin_app:emails/upload emails button click',
                  {
                    'workspace id': workspaceId,
                  }
                );
                fileUploadInput.current?.click();
              }}
            >
              Upload emails
              <input
                key={inputMultipleItems.length}
                ref={fileUploadInput}
                type="file"
                accept=".csv, text/plain"
                onChange={(event) => {
                  handleFileChange(event);
                }}
                multiple
                style={{ display: 'none' }}
              />
            </DSButton>
            <InfoIconWrapper>
              <DSIconInfo data-info-icon-file-upload></DSIconInfo>
              <UploadHint />
            </InfoIconWrapper>
          </ButtonWrapper>
        </DSColumn>
      )}
    </DSRow>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
`;

const InfoIconWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
`;
